import { useNavigate } from 'react-router-dom'
import { IMemberGlobalSearch } from 'types/member'
import { Button } from '../Button'

type Props = {
  query: string
  data: IMemberGlobalSearch[]
  total: number
  clearHandler: () => void
}

export const GlobalSearchTable = ({ query, data, total, clearHandler }: Props) => {
  const navigate = useNavigate()
  const showUser = (id: string) => {
    navigate(`/member/${id}`)
    clearHandler()
  }
  const membersListHandler = () => {
    navigate(`/member?page=1&perPage=10&searchExtended=${query}`)
    clearHandler()
  }
  return (
    <table className='w-max'>
      <thead>
        <tr>
          <th className='pb-1 pr-6 text-left text-xs font-thin italic'>Imię i nazwisko</th>
          <th className='pb-1 pr-6 text-left text-xs font-thin italic'>Oddział (okręg)</th>
          <th className='pb-1 pr-6 text-left text-xs font-thin italic'>Legitymacja</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} onClick={() => showUser(item.id)} className='cursor-pointer'>
            <td className='py-1 pr-6'>{item.name}</td>
            <td className='py-1 pr-6'>{item.unit}</td>
            <td className='py-1'>{item.card}</td>
          </tr>
        ))}
        {total > 4 && (
          <tr>
            <td colSpan={3} className='py-1 text-center'>
              <Button
                type='button'
                label='Pokaż wszystkie'
                onClick={membersListHandler}
                className='mt-2'
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default GlobalSearchTable

import { useMutation, useQuery } from '@tanstack/react-query'
import { ProgressInside } from 'components/shadcn/ui/progress-inside'
import { queryKeys } from 'constants/queryKeys'
import { closeCensusRegion, getCensusRegions, reopenCensusRegion } from 'fetchers/censusFetchers'
import { path } from 'constants/path'
import useAuth from 'hooks/useAuth'
import { Fragment, useMemo, useState } from 'react'
import { Button as AtomButton } from 'components/Atoms/Button'
import { Link, useParams } from 'react-router-dom'
import { Table } from 'components/Atoms/Table'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { errorForbiddenHandler } from 'tools/queryHelpers'
import { Card } from 'components/Atoms/Card'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from 'components/shadcn/ui/breadcrumb'
import { getRegion } from 'fetchers/regionFetchers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast } from 'tools/ToastHelpers'
import { notificationErrors } from 'error-data/notification'
import { BarChartComponent } from 'views/Census/charts/BarChartComponent'
import ChartHorizontal from 'views/Census/charts/ChartHorizontal'
import { ProgressChart } from 'views/Census/charts/ProgressChart'
import { SkeletonAreaChartComponent } from 'views/Census/charts/SkeletonAreaChartComponent'
import { SkeletonProgressChart } from 'views/Census/charts/SkeletonProgressChart'
import { SkeletonTimeLeftChart } from 'views/Census/charts/SkeletonTimeLeftChart'
import { TimeLeftChart } from 'views/Census/charts/TimeLeftChart'
import CensusBreadcrumbs from 'components/Atoms/Breadcrumbs/CensusBreadcrumbs'

const CensusDashboardRegion = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { userToken } = useAuth()
  const { censusId, regionId } = useParams()

  const {
    data: censusRegionData,
    isPending: isCensusRegionDataPending,
    isLoading: isCensusRegionDataLoading,
    error: censusRegionDataError,
    refetch: refetchCensusRegionData,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusDashboard, censusId],
    queryFn: () => getCensusRegions(censusId ?? '', regionId ?? ''),
  })

  const { data: regionData, isLoading: isRegionDataLoading } = useQuery({
    queryKey: [userToken, queryKeys.region, regionId],
    queryFn: () => getRegion(regionId ?? ''),
    enabled: !!regionId,
  })

  const closeCensusRegionMutation = useMutation({
    mutationFn: async ({ censusID, regionId }: { censusID: string; regionId: string }) =>
      closeCensusRegion(censusID, regionId),
    onSuccess: () => {
      successToast('Spis okręgu został zamknięty.')
      setIsLoading(false)
      refetchCensusRegionData()
    },
    onError: (error: any) => {
      mutationErrorHandler(error, notificationErrors.update, 'Nie udało się zamknąć okręgu.')
      setIsLoading(false)
    },
  })

  const reopenCensusRegionMutation = useMutation({
    mutationFn: async ({ censusID, regionId }: { censusID: string; regionId: string }) =>
      reopenCensusRegion(censusID, regionId),
    onSuccess: () => {
      successToast('Spis okręgu został otwarty.')
      setIsLoading(false)
      refetchCensusRegionData()
    },
    onError: (error: any) => {
      mutationErrorHandler(error, notificationErrors.update, 'Nie udało się otworzyć okręgu.')
      setIsLoading(false)
    },
  })

  const handleRegionMutation = async () => {
    setIsLoading(true)
    try {
      // Calculate the progress value
      const progressValue =
        (censusRegionData?.completedUnitsNum / censusRegionData?.totalUnitsNum) * 100

      // If the census is not completed, and progress is less than 100, show the progress confirmation
      if (!censusRegionData?.isCompleted && progressValue < 100) {
        const incompleteConfirmed = window.confirm(
          'Czy na pewno chcesz zamknąć spis okręgu, kiedy nie wszystkie oddziały ukończyły swój spis?',
        )
        if (!incompleteConfirmed) {
          setIsLoading(false)
          return // Exit if the user clicks "Cancel"
        }
      }

      // Show the final confirmation for opening or closing the census
      const action = censusRegionData?.isCompleted ? 'otworzyć' : 'zamknąć'
      const finalConfirmed = window.confirm(`Czy jesteś pewien, że chcesz ${action} spis okręgu?`)
      if (!finalConfirmed) {
        setIsLoading(false)
        return // Exit if the user clicks "Cancel"
      }

      // Proceed with the mutation based on the current region status
      if (censusRegionData?.isCompleted) {
        reopenCensusRegionMutation.mutate(
          { censusID: censusId ?? '', regionId: regionId ?? '' },
          {
            onSuccess: () => {
              refetchCensusRegionData()
              setIsLoading(false)
            },
          },
        )
      } else {
        closeCensusRegionMutation.mutate(
          { censusID: censusId ?? '', regionId: regionId ?? '' },
          {
            onSuccess: () => {
              refetchCensusRegionData()
              setIsLoading(false)
            },
          },
        )
      }
    } catch (error) {
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zmienić statusu okręgu.')
      setIsLoading(false)
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Oddział',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return row.original?.unit?.name
        },
      },
      {
        Header: 'Czy potwierdzone?',
        accessor: 'isCompleted',
        Cell: ({ row }: any) => {
          return row.original?.isCompleted ? 'Tak' : 'Nie'
        },
      },
      {
        Header: 'Bilans liczby członków (+/-)',
        accessor: 'balanceChange',
        Cell: ({ row }: any) => {
          return (
            <span
              className={`${
                row.original?.balanceChangeIn > row.original?.balanceChangeOut
                  ? 'text-green-500'
                  : row.original?.balanceChangeIn < row.original?.balanceChangeOut
                    ? 'text-red-500'
                    : ''
              }`}
            >{`${row.original?.balanceChangeIn - row.original?.balanceChangeOut} (${
              row.original?.balanceChangeIn
            }/${row.original?.balanceChangeOut})`}</span>
          )
        },
      },
      {
        Header: 'Ukończone jednostki',
        accessor: 'progress',
        Cell: ({ row }: any) => {
          const { completedUnitsNum, totalUnitsNum } = row.original

          if (isNaN(completedUnitsNum) || isNaN(totalUnitsNum) || totalUnitsNum === 0) {
            return <ProgressInside value={0} completed={0} total={0} />
          }

          const progressValue = (completedUnitsNum / totalUnitsNum) * 100

          return (
            <ProgressInside
              value={progressValue}
              completed={completedUnitsNum}
              total={totalUnitsNum}
            />
          )
        },
      },
      {
        Header: 'Szczegóły',
        accessor: 'details',
        Cell: ({ row }: any) => {
          return (
            <Link to={`/census/dashboard/${censusId}/branch/${row.original?.unit?.id}`}>
              <AtomButton variant='secondary' label='Zobacz dashboard oddziału' />
            </Link>
          )
        },
      },
    ]
  }, [])

  if (isCensusRegionDataLoading || isRegionDataLoading) {
    return (
      <div>
        <div className='chart-wrapper mx-auto grid items-start gap-6 pb-2 xl:grid-cols-2'>
          <div className='flex h-full flex-col flex-wrap gap-6 md:flex-row'>
            <div className='grid flex-1 gap-6 md:grid-cols-2'>
              <SkeletonTimeLeftChart />
              <SkeletonTimeLeftChart />
            </div>
            <div className='inline-block w-full'>
              <SkeletonProgressChart />
            </div>
          </div>
          <SkeletonAreaChartComponent />
        </div>
        <Card className='flex flex-col'>
          <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
            {[...Array(6)].map((_, index) => (
              <Fragment key={index}>
                <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                  <div className='h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200'></div>
                </dt>
                <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none'>
                  <div className='h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200'></div>
                </dd>
              </Fragment>
            ))}
          </dl>
        </Card>
      </div>
    )
  }

  if (errorForbiddenHandler(censusRegionDataError)) return <ErrorRoleModal />

  return (
    <div>
      <div className='mb-6 flex items-center gap-4'>
        <CensusBreadcrumbs
          censusId={censusRegionData?.id}
          regionId={regionData?.id}
          censusName={censusRegionData?.name}
          regionName={regionData?.name}
        />
      </div>
      <div className='chart-wrapper mx-auto grid items-start gap-6 pb-2 xl:grid-cols-2'>
        <div className='flex h-full flex-col flex-wrap gap-6 md:flex-row'>
          <div className='grid flex-1 gap-6 md:grid-cols-2'>
            <TimeLeftChart
              data={{
                dataKey: 'allDays',
                dateTo: censusRegionData?.dateTo,
                colorFill: '#FFC107',
                dateFrom: censusRegionData?.dateFrom,
              }}
              action={handleRegionMutation}
              actionText={
                censusRegionData?.isCompleted
                  ? 'Otwórz spis Okręgu'
                  : 'Zamknij spis Okręgu'
              }
              isCompleted={censusRegionData?.isCompleted}
              isPending={isLoading}
              isEditable={censusRegionData?.isEditable}
              isReopenable={censusRegionData?.isReopenable}
            />
            <ChartHorizontal
              data={{
                balanceChangeIn: censusRegionData?.balanceChangeIn,
                balanceChangeOut: censusRegionData?.balanceChangeOut,
              }}
            />
          </div>
          <div className='inline-block w-full'>
            <ProgressChart
              data={{
                total: censusRegionData?.totalUnitsNum,
                done: censusRegionData?.completedUnitsNum,
                value:
                  (censusRegionData?.completedUnitsNum / censusRegionData?.totalUnitsNum) * 100,
              }}
            />
          </div>
        </div>
        <BarChartComponent censusBranchData={censusRegionData} dashbordType='oddziałów' />
      </div>
      <Table data={censusRegionData?.subresults || []} columns={columns} />
    </div>
  )
}

export default CensusDashboardRegion

import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'

export const getCensusList = async (page: number, pageSize: number, filters: any) => {
  const res = await publicBaseApiURL.get(
    `${endpoint.census.list}?page=${page}&perPage=${pageSize}${filters || ''}`,
    {},
  )
  return res?.data
}

export const getCensusListMe = async (page: number, pageSize: number, filters: any) => {
  const res = await publicBaseApiURL.get(
    `${endpoint.census.listActiveMe}?page=${page}&perPage=${pageSize}${filters || ''}`,
    {},
  )
  return res?.data
}

export const getUnitCensusList = async (id: string, page: number, pageSize: number, filters: any) => {
  const res = await publicBaseApiURL.get(endpoint.census.censusListUnit + id + `?page=${page}&perPage=${pageSize}${filters || ''}`, {})
  return res?.data
}

export const createCensus = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.census.create, data)
  return res?.data
}

export const updateCensus = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.census.update(id), data)
  return res?.data
}

export const getCensusDetails = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.census.byId(id), {})
  return res?.data
}

export const getCensusAdminDashboardData = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.census.dashboard(id), {})
  return res?.data
}

export const getCensusRegions = async (censusId: string, regionId: string) => {
  const res = await publicBaseApiURL.get(endpoint.census.dashboardRegion(censusId, regionId), {})
  return res?.data
}

export const getCensusBranches = async (censusId: string, branchId: string) => {
  const res = await publicBaseApiURL.get(endpoint.census.dashboardBranch(censusId, branchId), {})
  return res?.data
}

export const getCensusListBranches = async (branchId: string) => {
  const res = await publicBaseApiURL.get(endpoint.census.listBranches(branchId), {})
  return res?.data
}

export const getCensusMembersSectionList = async (censusId: string, sectionId: string) => {
  const res = await publicBaseApiURL.get(
    endpoint.census.membersSectionList(censusId, sectionId),
    {},
  )
  return res?.data
}

export const getCensusMembersBranchList = async (censusId: string, branchId: string) => {
  const res = await publicBaseApiURL.get(endpoint.census.membersBranchList(censusId, branchId), {})
  return res?.data
}

export const markCensusMemberAsConfirmed = async (censusId: string, censusMemberId: string) => {
  const res = await publicBaseApiURL.post(
    endpoint.census.markMemberAsConfirmed(censusId, censusMemberId),
    {},
  )
  return res?.data
}

export const markCensusMemberAsUnconfirmed = async (censusId: string, censusMemberId: string) => {
  const res = await publicBaseApiURL.post(
    endpoint.census.markMemberAsUnconfirmed(censusId, censusMemberId),
    {},
  )
  return res?.data
}

export const markCensusMemberAsRemoved = async (
  censusId: string,
  censusMemberId: string,
  data: { reason: string; description: string },
) => {
  const res = await publicBaseApiURL.post(
    endpoint.census.markMemberAsRemoved(censusId, censusMemberId),
    data,
  )
  return res?.data
}

export const markCensusMemberAsNotRemoved = async (censusId: string, censusMemberId: string) => {
  const res = await publicBaseApiURL.post(
    endpoint.census.markMemberAsNotRemoved(censusId, censusMemberId),
    {},
  )
  return res?.data
}

export const addMemberToCensusSection = async (censusId: string, sectionId: string, data: any) => {
  const res = await publicBaseApiURL.post(
    endpoint.census.addMemberToSection(censusId, sectionId),
    data,
  )
  return res?.data
}

export const addMemberToCensusBranch = async (censusId: string, branchId: string, data: any) => {
  const res = await publicBaseApiURL.post(
    endpoint.census.addMemberToBranch(censusId, branchId),
    data,
  )
  return res?.data
}

export const getAddedMembersCensusSectionList = async (censusId: string, branchId: string) => {
  const res = await publicBaseApiURL.get(
    endpoint.census.addedMembersSectionList(censusId, branchId),
    {},
  )
  return res?.data
}

export const getAddedMembersCensusBranchList = async (censusId: string, branchId: string) => {
  const res = await publicBaseApiURL.get(
    endpoint.census.addedMembersBranchList(censusId, branchId),
    {},
  )
  return res?.data
}

export const getCensusBranchResult = async (censusId: string, branchId: string) => {
  const res = await publicBaseApiURL.get(endpoint.census.branchResult(censusId, branchId), {})
  return res?.data
}

export const getCensusSectionResult = async (censusId: string, sectionId: string) => {
  const res = await publicBaseApiURL.get(endpoint.census.sectionResult(censusId, sectionId), {})
  return res?.data
}

export const getCensusSectionSummary = async (censusId: string, sectionId: string) => {
  const res = await publicBaseApiURL.get(
    endpoint.census.sectionResultDetails(censusId, sectionId),
    {},
  )
  return res?.data
}

export const closeCensusSection = async (censusId: string, sectionId: string) => {
  const res = await publicBaseApiURL.post(endpoint.census.closeSection(censusId, sectionId), {})
  return res?.data
}

export const reopenCensusSection = async (censusId: string, sectionId: string) => {
  const res = await publicBaseApiURL.post(endpoint.census.reopenSection(censusId, sectionId), {})
  return res?.data
}

export const closeCensusBranch = async (censusId: string, branchId: string) => {
  const res = await publicBaseApiURL.post(endpoint.census.closeBranch(censusId, branchId), {})
  return res?.data
}

export const reopenCensusBranch = async (censusId: string, branchId: string) => {
  const res = await publicBaseApiURL.post(endpoint.census.reopenBranch(censusId, branchId), {})
  return res?.data
}

export const closeCensusRegion = async (censusId: string, regionId: string) => {
  const res = await publicBaseApiURL.post(endpoint.census.closeRegion(censusId, regionId), {})
  return res?.data
}

export const reopenCensusRegion = async (censusId: string, regionId: string) => {
  const res = await publicBaseApiURL.post(endpoint.census.reopenRegion(censusId, regionId), {})
  return res?.data
}

export const closeCensus = async (censusId: string) => {
  const res = await publicBaseApiURL.post(endpoint.census.close(censusId), {})
  return res?.data
}

export const rejectCensusSectionResults = async (
  censusId: string,
  sectionId: string,
  data: any,
) => {
  const res = await publicBaseApiURL.post(
    endpoint.census.rejectSectionResults(censusId, sectionId),
    data,
  )
  return res?.data
}

export const getCensusSectionResultsRejections = async (censusId: string, sectionId: string) => {
  const res = await publicBaseApiURL.get(
    endpoint.census.sectionResultsRejections(censusId, sectionId),
    {},
  )
  return res?.data
}

export const acknowledgeCensusSectionResultsRejection = async (
  censusId: string,
  sectionId: string,
  rejectionId: string,
) => {
  const res = await publicBaseApiURL.post(
    endpoint.census.acknowledgeSectionResultsRejection(censusId, sectionId, rejectionId),
    {},
  )
  return res?.data
}

export const getCensusSurvey = async (censusId: string) => {
  const res = await publicBaseApiURL.get(endpoint.census.survey(censusId), {})
  return res?.data
}

export const setCensusSurveyValue = async (censusId: string, censusMemberId: string, data: any) => {
  const res = await publicBaseApiURL.post(
    endpoint.census.setCensusSurveyValue(censusId, censusMemberId),
    data,
  )
  return res?.data
}

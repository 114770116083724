import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'app/hooks'
import { Button } from 'components/Atoms/Button'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { Button as ShadcnButton } from 'components/shadcn/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/shadcn/ui/card'

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/ui/select'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { queryKeys } from 'constants/queryKeys'
import { getCensusList } from 'fetchers/censusFetchers'
import { useLastPage } from 'hooks/useLastPage'
import useSearchModule from 'hooks/useSearchModule'
import { path } from 'constants/path'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { errorForbiddenHandler } from 'tools/queryHelpers'
import useSearch from 'hooks/useSearch'
import { Pagination } from 'components/Atoms/Pagination'
import { HoverIcon } from 'components/Atoms/HoverIcon'

const searchParams = {
  simpleParams: [],
  arrayParams: [],
}

const CensusList = () => {
  const { filters, currentPage } = useSearchModule({ archive: false })
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const { page, setLimit, changePage } = useSearch(searchParams)
  const navigate = useNavigate()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const perPageFromUrl = parseInt(queryParams.get('perPage') || '10', 10)

  const [limit, setLimitState] = useState(perPageFromUrl)

  const {
    data: censusList,
    isLoading: censusListLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusList, currentPage, filters, limit],
    queryFn: () => getCensusList(currentPage, limit, filters),
  })

  const lastPage = useLastPage(censusList?.pagination, censusListLoading)

  const onLimitHandler = (value: string) => {
    const newLimit = parseInt(value, 10)
    setLimitState(newLimit)
    setLimit(newLimit)
    const newSearchParams = new URLSearchParams(location.search)
    newSearchParams.set('perPage', newLimit.toString())
    navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true })
  }

  useEffect(() => {
    refetch()
  }, [limit])

  const censusListData = censusList?.items || []

  const getStatusText = (status: string) => {
    switch (status) {
      case 'awaiting':
        return 'Oczekiwanie'
      case 'in_progress':
        return 'W trakcie'
      case 'completed':
        return 'Zakończony'
      case 'canceled':
        return 'Anulowany'
      default:
        return 'Brak statusu'
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Nazwa',
        accessor: 'name',
      },
      {
        Header: 'Rozpoczęcie',
        accessor: 'dateFrom',
      },
      {
        Header: 'Zakończenie',
        accessor: 'dateTo',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }: any) => {
          return getStatusText(row?.original?.status)
        },
      },
      {
        Header: 'Akcje',
        accessor: 'section',
        Cell: ({ row }: any) => {
          return (
            <div className='flex gap-2'>
              <HoverIcon
                disabled={
                  row.original?.status === 'canceled' || row.original?.status === 'awaiting'
                }
                iconName='EyeIcon'
                title='Zobacz szczegóły spisu'
                onClick={() => navigate(`/census/${row.original?.id}/show`)}
              />
              <HoverIcon
                disabled={
                  row.original?.status === 'canceled' || row.original?.status === 'completed'
                }
                iconName='PencilIcon'
                title='Edytuj spis'
                onClick={() => navigate(`/census/${row.original?.id}/edit`)}
              />
            </div>
          )
        },
      },
    ]
  }, [])

  if (errorForbiddenHandler(error)) return <ErrorRoleModal />

  if (censusListLoading)
    return (
      <div>
        <div className='mb-4 h-4 w-full max-w-lg animate-pulse rounded-md bg-slate-300'></div>
        <div className='flex justify-between'>
          <div className='mb-4 h-4 w-full max-w-sm animate-pulse rounded-md bg-slate-300'></div>
          <div className='mb-4 h-4 w-full max-w-xs animate-pulse rounded-md bg-slate-300'></div>
        </div>
        <Card>
          <CardContent>
            <div className='my-4 h-4 w-full max-w-sm animate-pulse rounded-md bg-slate-300'></div>
            <div className='mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300'></div>
            <div className='mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300'></div>
            <div className='h-4 w-full max-w-xs animate-pulse rounded-md bg-slate-300'></div>
          </CardContent>
        </Card>
      </div>
    )

  return (
    <>
      <Card>
        <CardContent>
          <CardHeader className='flex flex-row items-center justify-between px-0'>
            <CardTitle className='text-2xl'>Lista wszystkich spisów</CardTitle>
            <ShadcnButton
              variant='outline'
              className=''
              onClick={() => navigate(path.census.create)}
            >
              Utwórz spis
            </ShadcnButton>
          </CardHeader>
          <div className={'flex items-center justify-between'}>
            <div className='flex items-center gap-4'>
              <Typography weight='semibold' className='whitespace-nowrap text-sm'>
                Liczba wierszy na stronę
              </Typography>
              <Select onValueChange={(value) => onLimitHandler(value)}>
                <SelectTrigger className='w-full max-w-[80px]' id='censusSelect'>
                  <SelectValue placeholder={limit.toString()} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className='max-w-sm'>
                    <SelectItem value='10'>10</SelectItem>
                    <SelectItem value='20'>20</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <span>
              {lastPage > 1 && (
                <Pagination lastPage={lastPage} currentPage={page} handlePageChange={changePage} />
              )}
            </span>
          </div>

          <Table maxColumnWidth='300px' columns={columns} data={censusListData || []} />
        </CardContent>
      </Card>
      <div className='my-4 flex justify-center gap-4'>
        <Button
          variant='secondary'
          label='Powrót'
          onClick={(e) => {
            e.preventDefault()
            navigate(path.dashboard)
          }}
        />
      </div>
    </>
  )
}

export default CensusList

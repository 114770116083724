import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { MultiSelect } from 'react-multi-select-component'
import { AxiosError } from 'axios'

import { Card } from 'components/Atoms/Card'
import { TextField } from 'components/Atoms/TextField'
import { Calendar } from 'components/Atoms/Calendar'
import { Button } from 'components/Atoms/Button'

import { useAppSelector } from 'app/hooks'
import { queryKeys } from 'constants/queryKeys'
import { getBranchesListPrefiltred } from 'fetchers/branchFetchers'
import { getRegionsList } from 'fetchers/regionFetchers'
import { getCensusDetails, updateCensus } from 'fetchers/censusFetchers'
import { errorQuery } from 'tools/queryHelpers'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { path } from 'constants/path'
import { IAxiosErrors } from 'types/axios-errors'
import { censusCreate } from 'error-data/census'
import { IUnitSelectedUnits } from 'types/structure'
import { Card as ShadCard, CardContent } from 'components/shadcn/ui/card'

const selectedUnitsInit: IUnitSelectedUnits = {
  region: [],
  branch: [],
  section: [],
  institution: [],
}

const CensusUpdate = () => {
  const { id } = useParams<{ id: string }>()
  const [censusName, setCensusName] = useState('')
  const [censusDateStart, setCensusDateStart] = useState<Date | null>(null)
  const [censusDateEnd, setCensusDateEnd] = useState<Date | null>(null)
  const [originalDateStart, setOriginalDateStart] = useState<Date | null>(null)
  const [originalDateEnd, setOriginalDateEnd] = useState<Date | null>(null)
  const [submitting, setSubmitting] = useState(false)
  const [selectedUnits, setSelectedUnits] = useState<IUnitSelectedUnits>(selectedUnitsInit)
  const { userToken, unitContext } = useAppSelector((state: any) => state.authReducer)
  const { control, setValue } = useForm()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const isRegion = unitContext?.type === 'region'

  // Fetch census details
  const { data: censusDetails, isLoading: isCensusDetailsLoading } = useQuery({
    queryKey: [userToken, queryKeys.censusDetails, id],
    queryFn: () => getCensusDetails(id as string),
    enabled: !!id,
  })

  // Fetch all regions
  const { data: regions, isLoading: isRegionsLoading } = useQuery({
    queryKey: [userToken, queryKeys.regionList],
    queryFn: () => getRegionsList(1, 1000, []),
    retry: errorQuery,
  })

  // Fetch all branches
  const { data: branches, isLoading: isBranchesLoading } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesListPrefiltred(1, 1000, ''),
    retry: errorQuery,
  })

  useEffect(() => {
    if (censusDetails) {
      setCensusName(censusDetails.name)
      const startDate = new Date(censusDetails.dateFrom)
      const endDate = new Date(censusDetails.dateTo)
      setCensusDateStart(startDate)
      setCensusDateEnd(endDate)
      setOriginalDateStart(startDate)
      setOriginalDateEnd(endDate)
      setValue('dateStart', startDate)
      setValue('dateEnd', endDate)
      setSelectedUnits({
        ...selectedUnitsInit,
        region: censusDetails.selectedRegions,
        branch: censusDetails.selectedBranches.map((branch: any) => branch.id),
      })
    }
  }, [censusDetails, setValue])

  useEffect(() => {
    if (isRegion) {
      setSelectedUnits((prevUnits) => ({ ...prevUnits, region: [unitContext.id] }))
    }
  }, [unitContext?.id, isRegion, userToken])

  const regionOptions = useMemo(() => {
    return regions?.items?.map((item: any) => ({ label: item.name, value: item.id }))
  }, [regions])

  const branchOptions = useMemo(() => {
    return branches?.items?.map((item: any) => ({ label: item.name, value: item.id }))
  }, [branches])

  const mutation = useMutation({
    mutationFn: (data: any) => updateCensus(id as string, data),
    onSuccess: () => {
      successToast('Spis został zaktualizowany')
      queryClient.invalidateQueries({ queryKey: [userToken, queryKeys.documentList] })
      navigate('/census')
      setSubmitting(false)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      setSubmitting(false)
      mutationErrorHandler(error, censusCreate.update, 'Nie udało się zaktualizować spisu')
    },
  })

  const normalizeDate = (date: Date | null) => {
    return date ? new Date(date.getFullYear(), date.getMonth(), date.getDate()) : null
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSubmitting(true)

    if (!censusName) {
      errorToast('Wpisz nazwę spisu')
      setSubmitting(false)
      return
    }

    if (!censusDateStart || !censusDateEnd || !originalDateStart || !originalDateEnd) {
      errorToast('Wybierz daty rozpoczęcia i zakończenia')
      setSubmitting(false)
      return
    }

    const normalizedStartDate = normalizeDate(censusDateStart)
    const normalizedEndDate = normalizeDate(censusDateEnd)
    const normalizedOriginalStartDate = normalizeDate(originalDateStart)
    const normalizedOriginalEndDate = normalizeDate(originalDateEnd)

    if (
      normalizedStartDate &&
      normalizedOriginalStartDate &&
      normalizedStartDate < normalizedOriginalStartDate
    ) {
      errorToast('Data rozpoczęcia nie może być wcześniejsza niż pierwotna data startu')
      setSubmitting(false)
      return
    }

    if (
      normalizedStartDate &&
      normalizedOriginalEndDate &&
      normalizedStartDate > normalizedOriginalEndDate
    ) {
      errorToast('Data rozpoczęcia nie może być późniejsza niż pierwotna data zakończenia')
      setSubmitting(false)
      return
    }

    if (normalizedStartDate && normalizedEndDate && normalizedStartDate >= normalizedEndDate) {
      errorToast('Data rozpoczęcia nie może być późniejsza niż data zakończenia')
      setSubmitting(false)
      return
    }

    mutation.mutate({
      name: censusName,
      selectedRegions: selectedUnits.region,
      selectedBranches: selectedUnits.branch,
      dateFrom: censusDateStart.toISOString().split('T')[0],
      dateTo: censusDateEnd.toISOString().split('T')[0],
    })
  }

  if (isCensusDetailsLoading || isRegionsLoading || isBranchesLoading) {
    return (
      <div>
        <div className='mb-4 h-4 w-full max-w-lg animate-pulse rounded-md bg-slate-300'></div>
        <div className='flex justify-between'>
          <div className='mb-4 h-4 w-full max-w-sm animate-pulse rounded-md bg-slate-300'></div>
          <div className='mb-4 h-4 w-full max-w-xs animate-pulse rounded-md bg-slate-300'></div>
        </div>
        <ShadCard>
          <CardContent>
            <div className='my-4 h-4 w-full max-w-sm animate-pulse rounded-md bg-slate-300'></div>
            <div className='mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300'></div>
            <div className='mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300'></div>
            <div className='h-4 w-full max-w-xs animate-pulse rounded-md bg-slate-300'></div>
          </CardContent>
        </ShadCard>
      </div>
    )
  }

  return (
    <>
      <Card label='Edytuj spis'>
        <form onSubmit={submitHandler}>
          <div className='grid grid-cols-1 gap-4 pb-4 sm:grid-cols-2 md:grid-cols-4'>
            <TextField
              onChange={(e) => setCensusName(e.target.value)}
              label='Nazwa spisu'
              placeholder='Wpisz nazwę spisu'
              name='censusName'
              type='text'
              value={censusName}
            />
            <div>
              <p className='mb-1 block text-sm font-medium text-gray-700'>Wybierz okręg</p>
              <MultiSelect
                options={regionOptions || []}
                value={selectedUnits.region.map((region) => ({
                  label: regionOptions?.find((option: any) => option.value === region)?.label,
                  value: region,
                }))}
                className='text-sm'
                onChange={(selected: any) =>
                  setSelectedUnits((prevUnits) => ({
                    ...prevUnits,
                    region: selected.map((region: any) => region.value),
                  }))
                }
                labelledBy='Select'
                overrideStrings={{
                  selectSomeItems: 'Wyszukaj',
                  search: 'Wyszukaj',
                  selectAll: 'Zaznacz wszystkie',
                  allItemsAreSelected: 'Wszystkie są zaznaczone',
                }}
              />
            </div>
            <div>
              <p className='mb-1 block text-sm font-medium text-gray-700'>Wybierz oddział</p>
              <MultiSelect
                options={branchOptions || []}
                value={selectedUnits.branch.map((branch) => ({
                  label: branchOptions?.find((option: any) => option.value === branch)?.label,
                  value: branch,
                }))}
                className='text-sm'
                onChange={(selected: any) =>
                  setSelectedUnits((prevUnits) => ({
                    ...prevUnits,
                    branch: selected.map((branch: any) => branch.value),
                  }))
                }
                labelledBy='Select'
                overrideStrings={{
                  selectSomeItems: 'Wyszukaj',
                  search: 'Wyszukaj',
                  selectAll: 'Zaznacz wszystkie',
                  allItemsAreSelected: 'Wszystkie są zaznaczone',
                }}
              />
            </div>
            <div></div>
            <div>
              <Controller
                name='dateStart'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Calendar
                    date={value}
                    handleDate={(val) => {
                      onChange(val)
                      setCensusDateStart(new Date(val))
                    }}
                    label='Data rozpoczęcia'
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name='dateEnd'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Calendar
                    date={value}
                    handleDate={(val) => {
                      onChange(val)
                      setCensusDateEnd(new Date(val))
                    }}
                    label='Data zakończenia'
                  />
                )}
              />
            </div>
          </div>
          <hr className='my-4' />
          <div className='mt-4 flex justify-center'>
            <Button label={submitting ? 'Czekaj...' : 'Zaktualizuj spis'} disabled={submitting} />
          </div>
        </form>
      </Card>
      <div className='my-4 flex justify-center gap-4'>
        <Button variant='secondary' label='Powrót do listy spisów' onClick={() => navigate(path.census.list)} />
      </div>
    </>
  )
}

export default CensusUpdate

import * as React from 'react'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import { cn } from 'components/lib/utils'

const ProgressInside = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
    value: number
    completed: number
    total: number
  }
>(({ className, value, completed, total, ...props }, ref) => {
  const progressText = `${value.toFixed(2)}% (${completed}/${total})`

  return (
    <ProgressPrimitive.Root
      ref={ref}
      className={cn(
        'relative h-8 w-full min-w-[175px] overflow-hidden rounded bg-primary/20',
        className,
      )}
      {...props}
    >
      <ProgressPrimitive.Indicator
        className={`h-full flex-1 transition-all ${
          value === 0
            ? 'bg-primary/20'
            : value < 20
              ? 'bg-red-500'
              : value < 40
                ? 'bg-orange-500'
                : value < 60
                  ? 'bg-yellow-500'
                  : value < 80
                    ? 'bg-green-500'
                    : 'bg-green-500'
        }`}
        style={{ width: `${value}%` }}
      />
      <div className='absolute inset-0 flex items-center justify-center text-sm font-semibold'>
        {progressText}
      </div>
    </ProgressPrimitive.Root>
  )
})

ProgressInside.displayName = ProgressPrimitive.Root.displayName

export { ProgressInside }

const TableSkeleton = () => {
  return (
    <div className='my-4'>
      <div className='rounded-md border bg-white'>
        <div className='relative w-full overflow-auto'>
          <table className='w-full caption-bottom text-sm'>
            <thead className='bg-slate-100 [&_tr]:border-b'>
              <tr>
                <th
                  scope='col'
                  className='h-12 whitespace-nowrap p-4 text-left align-middle text-xs font-normal text-muted-foreground text-slate-600 [&:has([role=checkbox])]:pr-0'
                >
                  <div className='h-4 w-[100px] animate-pulse rounded-md bg-slate-300'></div>
                </th>
                <th
                  scope='col'
                  className='h-12 whitespace-nowrap p-4 text-left align-middle text-xs font-normal text-muted-foreground text-slate-600 [&:has([role=checkbox])]:pr-0'
                >
                  <div className='h-4 w-[200px] animate-pulse rounded-md bg-slate-300'></div>
                </th>
                <th
                  scope='col'
                  className='h-12 whitespace-nowrap p-4 text-left align-middle text-xs font-normal text-muted-foreground text-slate-600 [&:has([role=checkbox])]:pr-0'
                >
                  <div className='h-4 w-[200px] animate-pulse rounded-md bg-slate-300'></div>
                </th>
                <th
                  scope='col'
                  className='h-12 whitespace-nowrap p-4 text-left align-middle text-xs font-normal text-muted-foreground text-slate-600 [&:has([role=checkbox])]:pr-0'
                >
                  <div className='h-4 w-[200px] animate-pulse rounded-md bg-slate-300'></div>
                </th>
                <th
                  scope='col'
                  className='h-12 whitespace-nowrap p-4 text-left align-middle text-xs font-normal text-muted-foreground text-slate-600 [&:has([role=checkbox])]:pr-0'
                >
                  <div className='h-4 w-[200px] animate-pulse rounded-md bg-slate-300'></div>
                </th>
                <th
                  scope='col'
                  className='h-12 whitespace-nowrap p-4 text-left align-middle text-xs font-normal text-muted-foreground text-slate-600 [&:has([role=checkbox])]:pr-0'
                >
                  <div className='h-4 w-[200px] animate-pulse rounded-md bg-slate-300'></div>
                </th>
              </tr>
            </thead>
            <tbody className='min-h-[909.5px]'>
              <tr className='border-b transition-colors hover:bg-slate-50'>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[80px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
              </tr>
              <tr className='border-b transition-colors hover:bg-slate-50'>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[80px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
              </tr>
              <tr className='border-b transition-colors hover:bg-slate-50'>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[80px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
              </tr>
              <tr className='border-b transition-colors hover:bg-slate-50'>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[80px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
              </tr>
              <tr className='border-b transition-colors hover:bg-slate-50'>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[80px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
              </tr>
              <tr className='border-b transition-colors hover:bg-slate-50'>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[80px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
              </tr>
              <tr className='border-b transition-colors hover:bg-slate-50'>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[80px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
              </tr>
              <tr className='border-b transition-colors hover:bg-slate-50'>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[80px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
              </tr>
              <tr className='border-b transition-colors hover:bg-slate-50'>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[80px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
              </tr>
              <tr className='border-b transition-colors hover:bg-slate-50'>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[80px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
              </tr>
              <tr className='border-b transition-colors hover:bg-slate-50'>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[80px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
                <td className='whitespace-nowrapp p-4 align-top text-sm font-medium text-gray-900'>
                  <div className='h-4 w-[180px] animate-pulse rounded-md bg-slate-200'></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TableSkeleton

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/shadcn/ui/card'

export function SkeletonAreaChartComponent() {
  return (
    <Card className='h-full'>
      <CardHeader>
        <div className='h-4 w-full max-w-[200px] animate-pulse rounded-md bg-slate-300'></div>
      </CardHeader>
      <CardContent className=''>
        <div className='my-4 h-[17rem] w-full animate-pulse rounded-md bg-slate-300'></div>
      </CardContent>
      <CardFooter>
        <div className='h-4 w-full max-w-[230px] animate-pulse rounded-md bg-slate-300'></div>
      </CardFooter>
    </Card>
  )
}

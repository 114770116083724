import { HoverIcon } from 'components/Atoms/HoverIcon'
import { useNavigate } from 'react-router-dom'
import { IListElement } from 'types/form'
import { ISectionClub } from 'types/section'

type Props = {
  data: ISectionClub
  archive?: boolean
  showModalHandler: (data: IListElement) => void
}

export const BranchActionCell = ({ data, archive, showModalHandler }: Props) => {
  const navigate = useNavigate()
  return (
    <div className='flex'>
      <HoverIcon iconName='EyeIcon' title='Zobacz' onClick={() => navigate(`/branch/${data.id}`)} />
      {!archive && (
        <>
          <HoverIcon
            iconName='PencilIcon'
            title='Edytuj'
            onClick={() => navigate(`/branch/${data.id}/update`)}
          />
          <HoverIcon
            iconName='ArchiveBoxIcon'
            title='Archiwizuj'
            onClick={() => showModalHandler(data)}
          />
          <HoverIcon
            iconName='ArrowDownOnSquareStackIcon'
            title='Połącz'
            onClick={() => navigate(`/branch/${data.id}/merge`)}
          />
        </>
      )}
    </div>
  )
}

export default BranchActionCell

import { useAppSelector } from 'app/hooks'
import { useParams } from 'react-router-dom'
import { verifyRole } from 'tools/roleHelpers'

export const useAuth = () => {
  const { id, memberId, termId, historyId } = useParams()
  const contextId = id as string
  const contextMemberId = memberId as string
  const contextTermId = termId as string
  const contextHistoryId = historyId as string
  const user = useAppSelector((state: any) => state?.authReducer)
  const userLoaded = user?.userInfo?.context?.role
  const unitContext = user?.userInfo?.context?.unit
  const userToken = user?.userToken

  // ADMIN VERIFY
  const isSAD = () => userLoaded && verifyRole.isSAD(userLoaded)
  const isOD = () => userLoaded && verifyRole.isIOD(userLoaded)
  const isInspector = () => userLoaded && verifyRole.isInspector(userLoaded)

  // STRUCTURE VERIFY
  const isPOK = () => userLoaded && verifyRole.isPOK(userLoaded)
  const isZOK = () => userLoaded && verifyRole.isZOK(userLoaded)
  const isPOD = () => userLoaded && verifyRole.isPOD(userLoaded)
  const isZOD = () => userLoaded && verifyRole.isZOD(userLoaded)
  const isPOG = () => userLoaded && verifyRole.isPOG(userLoaded)

  // GROUPS VERIFY
  const isAllAdmin = () => userLoaded && verifyRole.isAllAdmin(userLoaded)
  const isAdmins = () => userLoaded && verifyRole.isAdmins(userLoaded)
  const isAllOK = () => userLoaded && verifyRole.isAllOK(userLoaded)
  const isAllOD = () => userLoaded && verifyRole.isAllOD(userLoaded)

  const getHighestRoleLevel = () => {
    if (isSAD() || isAllAdmin() || isInspector()) return 'admin'
    if (isPOK() || isZOK()) return 'region'
    if (isPOD() || isZOD()) return 'branch'
    if (isPOG()) return 'institution'
    return 'none'
  }

  return {
    isSAD,
    isOD,
    isInspector,
    isPOK,
    isZOK,
    isPOD,
    isZOD,
    isPOG,
    isAllAdmin,
    isAdmins,
    isAllOK,
    isAllOD,
    userLoaded,
    unitContext,
    userToken,
    id: contextId,
    memberId: contextMemberId,
    termId: contextTermId,
    historyId: contextHistoryId,
    user,
    getHighestRoleLevel,
  }
}

export default useAuth

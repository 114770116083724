import { Button } from 'components/Atoms/Button'
import { Typography } from 'components/Atoms/Typography'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  label?: string | JSX.Element
  actionsButton?: Array<{
    variant?: 'primary' | 'secondary' | 'third'
    label?: string | JSX.Element
    icon?: any
    handleClick: (data: any) => void
    disabled?: boolean
  }>
  children: React.ReactNode
}

export const Card: React.FC<IProps> = ({ children, className, label, actionsButton, ...props }) => (
  <div className='modal-content mt-5'>
    {label ? (
      <div className='flex items-center justify-between bg-gray-300 px-3 py-2 md:rounded-t-lg'>
        <Typography size='lg' weight='semibold'>
          {label}
        </Typography>
        <div className='flex'>
          {(actionsButton || []).map((item, idx) => (
            <div key={idx} className='ml-2'>
              <Button
                variant={item.variant}
                label={item.label}
                icon={item.icon}
                onClick={item.handleClick}
                disabled={item.disabled}
              />
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div className='flex justify-end bg-gray-300 p-2'>
        <div className='flex'>
          {(actionsButton || []).map((item, idx) => (
            <div key={idx} className='ml-2'>
              <Button
                label={item.label}
                icon={item.icon}
                onClick={item.handleClick}
                disabled={item.disabled}
              />
            </div>
          ))}
        </div>
      </div>
    )}
    <div className={`w-full bg-white p-3 md:rounded-b-lg ${className ? className : ''}`} {...props}>
      {children}
    </div>
  </div>
)

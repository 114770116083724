import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { LabelTypography } from 'components/Atoms/LabelTypography'
import { TextField } from 'components/Atoms/TextField'
import { Dispatch, SetStateAction, useState } from 'react'
import { sensitivePersonalSchema } from 'schemas/memberSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { IMemberSensitive } from 'types/member'

type Props = {
  data: IMemberSensitive
  isSending?: boolean
  isEdit?: boolean
  isReveal?: boolean
  setIsEdit: (val: boolean) => void
  onReveal: Dispatch<SetStateAction<boolean>>
  onSubmit: (data: any) => void
}

export const SensitivePersonalData = ({
  data,
  isSending,
  isEdit,
  isReveal,
  setIsEdit,
  onReveal,
  onSubmit,
}: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      apartmentNr: data?.address?.apartmentNr || '',
      city: data?.address?.city || '',
      houseNr: data?.address?.houseNr || '',
      postalCode: data?.address?.postalCode || '',
      streetName: data?.address?.streetName || '',
    },
    resolver: yupResolver(sensitivePersonalSchema),
  })
  const addressUndefined = !data?.address
  const addressNumer = `${data?.address?.houseNr ? data?.address?.houseNr : ''}${
    data?.address?.apartmentNr ? ` / ${data?.address?.apartmentNr}` : ''
  }`
  const fullAddress = `${data?.address?.streetName}${addressNumer ? ` ${addressNumer}` : ''}, ${
    data?.address?.postalCode
  } ${data?.address?.city}`

  const handleVisibleEditField = () => {
    onReveal(true)
    setIsEdit(true)
  }

  if (isEdit) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          label='Wrażliwe dane osobowe'
          actionsButton={[
            {
              disabled: true,
              label: 'Edytuj dane',
              handleClick: () => handleSubmit(onSubmit),
            },
            {
              disabled: true,
              label: isReveal ? 'Ukryj dane' : 'Pokaż dane',
              handleClick: () => onReveal((prev) => !prev),
            },
          ]}
        >
          <div className='grid grid-cols-4'>
            <div className='mr-6'></div>
            <div className='col-span-3 ml-6'>
              <div className='flex flex-col gap-2'>
                <TextField
                  {...register('streetName')}
                  error={errors.streetName?.message}
                  label='Ulica'
                  placeholder='Ulica'
                />
                <div className='grid grid-cols-3 gap-5'>
                  <TextField
                    {...register('houseNr')}
                    error={errors.houseNr?.message}
                    label='Numer budynku'
                    placeholder='Numer budynku'
                  />
                  <TextField
                    {...register('apartmentNr')}
                    label='Numer lokalu'
                    placeholder='Numer lokalu'
                  />
                  <TextField
                    {...register('postalCode')}
                    error={errors.postalCode?.message}
                    label='Kod pocztowy'
                    placeholder='XX-XXX'
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const input = e.target.value.replace(/\D/g, '')
                      if (input.length > 2) {
                        setValue('postalCode', input.slice(0, 2) + '-' + input.slice(2, 5))
                      } else {
                        setValue('postalCode', input)
                      }
                    }}
                  />
                </div>
                <div>
                  <TextField
                    {...register('city')}
                    error={errors.city?.message}
                    label='Miejscowość'
                    placeholder='Miejscowość'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='mt-5 flex justify-center gap-5'>
            <Button label='Anuluj' variant='secondary' onClick={() => setIsEdit(false)} />
            <Button disabled={isSending} label='Zapisz' variant='primary' type='submit' />
          </div>
        </Card>
      </form>
    )
  }

  return (
    <Card
      label='Wrażliwe dane osobowe'
      actionsButton={[
        {
          disabled: isSending,
          label: 'Edytuj dane',
          handleClick: handleVisibleEditField,
        },
        {
          label: isReveal ? 'Ukryj dane' : 'Pokaż dane',
          handleClick: () => onReveal((prev) => !prev),
        },
      ]}
    >
      <div className='grid grid-cols-4'>
        <div className='mr-6'>
          <LabelTypography label='PESEL' text={data?.pesel} />
        </div>
        <div className='col-span-3 ml-6'>
          <LabelTypography
            label='Adres'
            text={addressUndefined ? 'Dane niedostępne' : fullAddress}
          />
        </div>
      </div>
    </Card>
  )
}

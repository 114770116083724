import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { BellIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { DotFilledIcon } from '@radix-ui/react-icons'
import {
  Tooltip,
  TooltipTrigger,
  TooltipProvider,
  TooltipContent,
} from 'components/shadcn/ui/tooltip'
import { Toast } from '../Toast'
import { getNotificationList, markAllNotificationsAsRead } from 'fetchers/notificationFetchers'
import { useMutation, useInfiniteQuery } from '@tanstack/react-query'
import { useAppSelector } from 'app/hooks'
import { queryKeys } from 'constants/queryKeys'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { AxiosError } from 'axios'
import { mutationErrorHandler } from 'tools/errorHandler'
import { notificationsListErrors } from 'error-data/notificationList'
import { Modal } from '../Modal'
import { BellAlertIcon, BellSlashIcon, CheckBadgeIcon } from '@heroicons/react/20/solid'
import { format, isToday, isYesterday } from 'date-fns'
import { pl } from 'date-fns/locale'

const LIMIT_INCREMENT = 10
const REFETCH_INTERVAL = 10000

export const Notification = () => {
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const [isSending, setIsSending] = useState(false)
  const [allowRead, setAllowRead] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const [refreshTrigger, setRefreshTrigger] = useState(0)
  const hasClickedRef = useRef(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  useEffect(() => {
    return () => {
      hasClickedRef.current = false
    }
  }, [])

  const {
    data: notificationsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status: notificationsListStatus,
    refetch: refetchNotificationsList,
  } = useInfiniteQuery<any, AxiosError, any, any>({
    queryKey: [userToken, queryKeys.notificationsListAlerts, allowRead, refreshTrigger],
    queryFn: ({ pageParam = 1 }) =>
      getNotificationList(
        pageParam as number,
        LIMIT_INCREMENT,
        allowRead ? '?filter[allowRead]' : '?filter[]',
      ),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.pagination.currentPage < lastPage.pagination.lastPage) {
        return lastPage.pagination.currentPage + 1
      }
      return undefined
    },
    initialPageParam: 1,
    retry: false,
    refetchInterval: isPopoverOpen ? REFETCH_INTERVAL : false,
  })

  console.log({ notificationsData })
  const toggleAllowRead = () => {
    setAllowRead((prev) => !prev)
    refetchNotificationsList()
  }

  const handleMarkAsRead = async (id: string) => {
    // Call the API to mark the notification as read
  }

  const mutateAllRead = useMutation({
    mutationFn: (notificationIds: string[]) => markAllNotificationsAsRead(notificationIds),
    onSuccess: () => {
      successToast('Wszystkie powiadomienia zostały oznaczone jako przeczytane')
      refetchNotificationsList()
      setIsSending(false)
      setIsModalOpen(false)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      setIsModalOpen(false)
      console.error(error)
      errorToast('Wystąpił błąd podczas oznaczania wszystkich powiadomień jako przeczytane')
      mutationErrorHandler(error, notificationsListErrors.message)
    },
  })

  const handleMarkAllAsRead = async () => {
    setIsSending(true)
    const notificationIds = notificationsData?.pages.flatMap((page: { items: any[] }) =>
      page.items
        .filter((notification: { readAt: any }) => !notification.readAt)
        .map((notification: { id: any }) => notification.id),
    )

    if (notificationIds && notificationIds.length > 0) {
      mutateAllRead.mutate(notificationIds)
    } else {
      successToast('Brak nieprzeczytanych powiadomień')
      setIsSending(false)
      setIsModalOpen(false)
    }
  }

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current
      const threshold = 10
      if (
        scrollHeight - scrollTop <= clientHeight + threshold &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage()
      }
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  useEffect(() => {
    const currentContainer = containerRef.current
    if (currentContainer) {
      currentContainer.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  useEffect(() => {
    if (notificationsData) {
      setTimeout(() => {
        containerRef.current?.dispatchEvent(new Event('scroll'))
      }, 0)
    }
  }, [notificationsData])

  const hasUnreadNotifications = notificationsData?.pages.some(
    (page: { items: { readAt: string }[] }) =>
      page.items.some((notification: { readAt: string }) => !notification.readAt),
  )

  const handleBellClick = () => {
    if (!hasClickedRef.current) {
      setRefreshTrigger((prev) => prev + 1)
      hasClickedRef.current = true
    }
    setIsPopoverOpen(true)
  }

  const formatDateLabel = (dateString: string) => {
    const date = new Date(dateString)

    if (isToday(date)) {
      return 'Dzisiaj'
    } else if (isYesterday(date)) {
      return 'Wczoraj'
    } else {
      return format(date, 'd MMMM', { locale: pl }) // Format date like "Październik 12"
    }
  }

  const deduplicateNotifications = (pages: any[]) => {
    const seenNotifications = new Set()
    return pages.flatMap((page: { items: any[] }) =>
      page.items.filter((notification) => {
        const uniqueKey = `${notification.id}-${notification.createdAt}`
        if (seenNotifications.has(uniqueKey)) {
          return false
        }
        seenNotifications.add(uniqueKey)
        return true
      }),
    )
  }

  const groupNotificationsByDate = (notifications: any[]) => {
    return notifications.reduce((acc: any, notification: any) => {
      const date = formatDateLabel(notification.createdAt)
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(notification)
      return acc
    }, {})
  }

  const dedupedNotifications = React.useMemo(
    () => deduplicateNotifications(notificationsData?.pages || []),
    [notificationsData],
  )

  const groupedNotifications = React.useMemo(
    () => groupNotificationsByDate(dedupedNotifications),
    [dedupedNotifications],
  )

  return (
    <TooltipProvider>
      <Popover className='relative'>
        {({ open }) => (
          <>
            <PopoverButton
              className='relative flex aspect-square items-center justify-center rounded-md p-1.5 hover:bg-gray-100'
              onClick={handleBellClick}
            >
              {open ? (
                <BellAlertIcon className='h-6 w-6 text-gray-500' />
              ) : (
                <BellIcon className='h-6 w-6 text-gray-500' />
              )}
              {hasUnreadNotifications && (
                <DotFilledIcon className='absolute right-0 top-0 h-6 w-6 text-red-500' />
              )}
            </PopoverButton>
            <PopoverPanel
              anchor='bottom end'
              transition
              className='z-10 flex origin-top flex-col rounded-md bg-white shadow-lg ring-1 ring-black/5 transition duration-200 ease-out focus:outline-none data-[closed]:-translate-y-1 data-[closed]:opacity-0'
            >
              <div className='min-w-[384px]'>
                <div className='flex items-center justify-between p-4 pt-6'>
                  <h2 className='text-lg font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
                    Powiadomienia
                  </h2>
                  <div className='flex items-center gap-4'>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button
                          onClick={() => setIsModalOpen(true)}
                          disabled={
                            isSending ||
                            !notificationsData ||
                            notificationsData.pages[0].items.length === 0
                          }
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          className=''
                        >
                          <CheckCircleIcon
                            className={`h-5 w-5 text-gray-500 ${isHovered ? 'hidden' : 'block'}`}
                          />
                          <CheckBadgeIcon
                            className={`h-5 w-5 text-blue-600 ${isHovered ? 'block' : 'hidden'}`}
                          />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent side='bottom'>
                        <span>Oznacz wszystkie jako przeczytane</span>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </div>
                <div
                  ref={containerRef}
                  className='mb-2 flex min-h-[250px] flex-col items-center gap-3 overflow-y-auto p-4 pr-2 pt-0'
                  id='notifications-panel'
                >
                  {notificationsListStatus === 'pending' ? (
                    <span className='flex items-center px-2 py-4 text-sm font-light leading-none text-gray-600 peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
                      <div className='loader mr-1'></div> Ładowanie powiadomień...
                    </span>
                  ) : notificationsListStatus === 'error' ? (
                    <p className='px-2 py-4 text-sm font-light leading-none text-red-600 peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
                      Wystąpił błąd podczas ładowania powiadomień.
                    </p>
                  ) : dedupedNotifications.length > 0 ? (
                    <div className='flex flex-col gap-4'>
                      {Object.entries(groupedNotifications).map(([dateLabel, notifications]) => (
                        <div key={dateLabel} className='flex w-full flex-col gap-2'>
                          <h3 className='text-sm font-semibold text-gray-500'>{dateLabel}</h3>

                          {(notifications as any[]).map((notification: any) => (
                            <Toast
                              key={`${notification.id}-${notification.createdAt}-${notification.header}`}
                              notification={notification}
                              onMarkAsRead={handleMarkAsRead}
                            />
                          ))}
                        </div>
                      ))}
                      {isFetchingNextPage && (
                        <div className='flex w-full justify-center p-2'>
                          <div className='notification-loader'></div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <p className='px-2 py-4 text-sm font-light leading-none text-gray-600 peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
                      Powiadomienia będą widoczne w tej sekcji
                    </p>
                  )}
                </div>
              </div>
            </PopoverPanel>
          </>
        )}
      </Popover>
      <Modal isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)}>
        <div className='p-6'>
          <h3 className='mb-4 text-lg font-medium leading-6 text-gray-900'>
            Oznacz wszystkie jako przeczytane
          </h3>
          <p className='mb-6 text-sm text-gray-500'>
            Czy na pewno chcesz oznaczyć wszystkie powiadomienia jako przeczytane?
          </p>
          <div className='mt-4 flex justify-end space-x-3'>
            <button
              type='button'
              className='inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
              onClick={() => setIsModalOpen(false)}
            >
              Anuluj
            </button>
            <button
              type='button'
              className='inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
              onClick={handleMarkAllAsRead}
              disabled={isSending}
            >
              {isSending ? 'Przetwarzanie...' : 'Potwierdź'}
            </button>
          </div>
        </div>
      </Modal>
    </TooltipProvider>
  )
}

import { Button } from 'components/Atoms/Button'
import { Calendar } from 'components/Atoms/Calendar'
import { Card } from 'components/Atoms/Card'
import { Select } from 'components/Atoms/Select'
import { path } from 'constants/path'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import useAuth from 'hooks/useAuth'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { queryKeys } from 'constants/queryKeys'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Typography } from 'components/Atoms/Typography'
import { getUnitPositionsList } from 'fetchers/unitPositionFetchers'
import { IStructureFunction, IStructureMemberFunction } from 'types/structure'
import { errorForbiddenHandler, errorNotExistsHandler, errorQuery } from 'tools/queryHelpers'
import useSearch from 'hooks/useSearch'
import { addMemberFunction, getMembersList } from 'fetchers/membersFetchers'
import SearchAndFetchInput from 'components/Atoms/SearchAndFetchInput/SearchAndFetchInput'
import { yupResolver } from '@hookform/resolvers/yup'
import { branchFunctionsForm } from 'schemas/branchFunctionsSchema'
import { errorRequires, successToast } from 'tools/ToastHelpers'
import { setupDate } from 'tools/formTools'
import ErrorRouteModal from 'components/Atoms/ErrorRouteModal'
import { toast } from 'react-toastify'
import { getRegion } from 'fetchers/regionFetchers'
import { AxiosError } from 'axios'
import { unitTermErrors } from 'error-data/unit'
import { mutationErrorHandler } from 'tools/errorHandler'

export const RegionFunctionsCreate = () => {
  const [isSending, setIsSending] = useState(false)
  const [selectedMember, setSelectedMember] = useState<string | null>(null)
  const [inPerPage, setInPerPage] = useState(10)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { userToken, userLoaded, isAllAdmin, isAllOK, id } = useAuth()
  const { filters, page } = useSearch({ simpleParams: ['search'] })
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(branchFunctionsForm),
  })

  // CHECK USER ROLE
  const isAdmin = isAllAdmin()
  const isRegion = isAllOK()

  // GET REGION
  const {
    data: region,
    isLoading: regionLoading,
    error: regionError,
  } = useQuery({
    queryKey: [userToken, queryKeys.region, userLoaded],
    queryFn: () => getRegion(id),
    retry: errorQuery,
  })
  const notExists = errorNotExistsHandler(regionError)
  const forbiddenRegion = errorForbiddenHandler(regionError)
  // END GET REGION

  // GET POSITIONS
  const {
    data: positions,
    isLoading: positionsLoading,
    error: positionsError,
  } = useQuery({
    queryKey: [userToken, queryKeys.positionsList, userLoaded],
    queryFn: () => {
      if (!userLoaded) return []
      return getUnitPositionsList(1, 1000)
    },
  })
  const positionList =
    positions?.items && positions?.items.length > 0
      ? positions?.items
          .filter((item: IStructureFunction) => item.unitType === 'region')
          .map((item: IStructureFunction) => ({ label: item?.name, value: item?.id }))
      : []

  // END GET POSITIONS

  // MEMBERS LOADER
  const { data: members, isLoading } = useQuery({
    queryKey: [userToken, queryKeys.membersList, page, inPerPage, filters, id, userLoaded],
    queryFn: () => {
      if (!userLoaded) return []
      return getMembersList(page, inPerPage, `&filter[region][]=${id}&unredacted=true`)
    },
    retry: errorQuery,
  })
  // Setup members list
  const membersList = members?.items
    ? members?.items.map((item: any) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      }))
    : []
  // Setup member loading state
  const membersLoading = isLoading || !userLoaded

  const searchMore = () => {
    const count =
      members?.pagination?.countFiltered > 0
        ? members?.pagination?.countFiltered
        : members?.pagination?.count
    if (!members && members?.items?.length < count) setInPerPage((prev) => prev + 10)
  }
  // END MEMBERS LOADER

  // SEND HANDLERS
  const mutation = useMutation({
    mutationFn: (data: IStructureMemberFunction) => addMemberFunction(data),
    onSuccess: () => {
      successToast('Przypisano nową funkcję.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.structureFunctions] })
      navigate(path.regionFunctions.list)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, unitTermErrors.create, 'Nie udało się przypisać funkcji.')
    },
  })

  const onSubmit = async (data: any) => {
    const formData = {
      ...data,
      unitId: id,
      startedAt: setupDate(data?.startedAt),
      endedAt: setupDate(data?.endedAt),
    }
    try {
      setIsSending(true)
      mutation.mutate(formData)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się przypisać funkcji.')
    }
  }
  // END SEND HANDLERS

  const onValidationFailure = () => errorRequires(errors)
  const memberError = !selectedMember && errors?.memberId ? 'Pole wymagane' : ''
  const positionsIdError = !selectedMember && errors?.unitPositionId ? 'Pole wymagane' : ''

  // ERROR HANDLERS
  if (!userLoaded || positionsLoading || regionLoading) return <LoadingSection />
  if (notExists) return <ErrorRouteModal />
  if ((!isAdmin && !isRegion) || forbiddenRegion) return <ErrorRoleModal />
  // END ERROR HANDLERS

  return (
    <section>
      <Typography size='xl' weight='semibold'>
        Dodaj funkcję {region?.name ? ` / ${region?.name} (${region?.parent?.name})` : ''}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit, onValidationFailure)}>
        <Card label='Przypisz członka do funkcji'>
          <div className='mb-5 grid gap-4 md:grid-cols-3'>
            <Controller
              name='unitPositionId'
              control={control}
              render={({ field: { onChange } }) => (
                <div>
                  <Select
                    handleSelect={({ value }) => onChange(value)}
                    label='Wskaż funkcję'
                    withEmpty
                    error={positionsIdError}
                    options={positionList}
                    isLoading={positionsLoading}
                  />
                  <p className='mt-2 text-red-700'>{positionsIdError}</p>
                </div>
              )}
            />
            <div>
              <Controller
                name='startedAt'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Calendar
                    date={value}
                    handleDate={(val) => onChange(val)}
                    label='Data rozpoczęcia kadencji'
                  />
                )}
              />{' '}
            </div>
            <SearchAndFetchInput
              searchName='search'
              externalChangeHandler={setSelectedMember}
              searchMore={searchMore}
              isLoading={membersLoading}
              name={'memberId'}
              label={'Wybierz członka'}
              register={register}
              options={membersList}
              control={control}
            >
              <p className='mt-2 text-red-700'>{memberError}</p>
            </SearchAndFetchInput>
          </div>

          <div className='mt-5 flex justify-center'>
            <div className='mr-5'>
              <Button
                label='Anuluj'
                variant='secondary'
                onClick={() => navigate(path.regionFunctions.list)}
              />
            </div>
            <Button disabled={isSending} label='Zatwierdź' type='submit' />
          </div>
        </Card>
      </form>
    </section>
  )
}

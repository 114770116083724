import { IMutationErrorType } from 'types/httpStatus.enum'

export const censusCreate: IMutationErrorType = {
  get: {
    message: 'Nie udało się utworzyć spisu.',
  },
}
export const censusErrors: IMutationErrorType = {
  get: {
    message: 'Nie udało się pobrać danych spisu.',
  },
  update: {
    message: 'Nie udało się zaktualizować danych spisu.',
  },
  remove: {
    message: 'Nie udało się usunąć spisu.',
  },
  create: {
    message: 'Nie udało się utworzyć spisu.',
  },
}

import { IMutationErrorType } from 'types/httpStatus.enum'

export const notificationsListErrors: IMutationErrorType = {
  markAllAsRead: {
    message: 'Nie udało się oznaczyć wszystkich powiadomień jako przeczytane',
    u422: 'Nie można oznaczyć wszystkich powiadomień jako przeczytane.',
  },
  markAsRead: {
    message: 'Nie udało się oznaczyć powiadomienia jako przeczytane',
    u422: 'Nie można oznaczyć zgłoszenia jako przeczytane.',
  },
}

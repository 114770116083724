import { BookOpenIcon, CheckIcon, FolderOpenIcon } from '@heroicons/react/20/solid'
import { LockClosedIcon, LockOpen1Icon } from '@radix-ui/react-icons'
import { Button } from 'components/shadcn/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/shadcn/ui/card'
import { ChartConfig, ChartContainer } from 'components/shadcn/ui/chart'
import { useState } from 'react'
import { Label, PolarGrid, PolarRadiusAxis, RadialBar, RadialBarChart } from 'recharts'

export const description = 'A radial chart with text'

const chartData = [{ browser: 'safari', visitors: 200, fill: 'var(--color-safari)' }]

const chartConfig = {
  visitors: {
    label: 'Visitors',
  },
  safari: {
    label: 'Safari',
    color: 'hsl(var(--chart-2))',
  },
} satisfies ChartConfig

interface TimeLeftChartProps {
  data: {
    dataKey: string
    dateTo: number
    dateFrom: string
    colorFill: string
  }
  action: () => void
  actionText: string
  isCompleted?: boolean
  isPending?: boolean
  isReopenable?: boolean
  isEditable?: boolean
}

export function TimeLeftChart({
  data,
  action,
  actionText,
  isCompleted,
  isPending,
  isReopenable,
  isEditable,
}: TimeLeftChartProps) {
  const [isHovered, setIsHovered] = useState(isCompleted)

  // Function to get the end of day for a given date
  const getEndOfDay = (date: Date) => {
    const endOfDay = new Date(date)
    return endOfDay
  }

  const startDate = new Date(data.dateFrom)
  const endDate = new Date(data.dateTo)
  const currentDate = new Date()

  const totalDays = Math.ceil((getEndOfDay(endDate).getTime() - startDate.getTime()) / 86400000)

  const daysLeft = Math.max(
    0,
    Math.ceil((getEndOfDay(endDate).getTime() - currentDate.getTime()) / 86400000),
  )

  const percentage = (daysLeft / totalDays) * 100

  // Determine the color based on the time left
  const getColor = () => {
    if (percentage > 70) {
      return 'hsl(var(--chart-2))' // Lots of time left
    } else if (percentage > 40) {
      return '#facc15' // Moderate time left
    } else if (percentage > 10) {
      return '#fb923c' // Getting close to deadline
    } else {
      return '#f87171' // Very little time left
    }
  }

  const dynamicColor = getColor()

  return (
    <Card className='flex flex-col'>
      <CardHeader className='items-center pb-0'>
        <CardTitle className='text-center'>Czas realizacji obowiązku</CardTitle>
        <CardDescription>
          {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}
        </CardDescription>
      </CardHeader>
      <CardContent className='flex-1 pb-0'>
        <ChartContainer config={chartConfig} className='mx-auto aspect-square max-h-[250px]'>
          <RadialBarChart
            data={chartData.map((item) => ({
              ...item,
              allDays: percentage,
              fill: dynamicColor, // Use the dynamic color here
            }))}
            startAngle={0}
            endAngle={(percentage / 100) * 360}
            innerRadius={80}
            outerRadius={110}
          >
            <PolarGrid
              gridType='circle'
              radialLines={false}
              stroke='none'
              className='first:fill-muted last:fill-background'
              polarRadius={[86, 74]}
            />
            <RadialBar dataKey='allDays' background cornerRadius={10} />
            <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
              <Label
                content={({ viewBox }) => {
                  if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor='middle'
                        dominantBaseline='middle'
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className='fill-foreground text-4xl font-bold'
                        >
                          {daysLeft}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className='fill-muted-foreground'
                        >
                          pozostało dni
                        </tspan>
                      </text>
                    )
                  }
                }}
              />
            </PolarRadiusAxis>
          </RadialBarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className='flex-col gap-2 text-sm'>
        <Button
          onClick={action}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          variant={isCompleted ? 'default' : 'success'}
          disabled={isPending || (!isReopenable && isCompleted) || (!isEditable && !isCompleted)}
        >
          {isPending ? <span className='loader'></span> : ''}
          {actionText}
          {isCompleted === false ? (
            <CheckIcon className='ml-1 h-4 w-4' />
          ) : (
            <FolderOpenIcon className='ml-1 h-4 w-4' />
          )}
        </Button>
      </CardFooter>
    </Card>
  )
}

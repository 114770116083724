import React, { useState } from 'react'
import { NotificationModal } from '../NotificationModal/NotificationModal'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

type NotificationLevel =
  | 'emergency'
  | 'alert'
  | 'critical'
  | 'error'
  | 'warning'
  | 'notice'
  | 'info'
  | 'debug'

type ToastProps = {
  notification: {
    id: string
    header: string
    content: string
    readAt: string | null
    type: string
    createdAt: string
    level: NotificationLevel
    metadata: { censusId: string }
  }
  onMarkAsRead: (id: string) => void
}

const getNotificationColor = (level: NotificationLevel) => {
  switch (level) {
    case 'emergency':
    case 'alert':
    case 'critical':
    case 'error':
      return 'hover:bg-red-50 hover:border-red-200'
    case 'warning':
    case 'notice':
      return 'hover:bg-yellow-50 hover:border-yellow-200'
    case 'info':
    case 'debug':
      return 'hover:bg-sky-50 hover:border-sky-200'
    default:
      return 'hover:bg-gray-50 hover:border-gray-200'
  }
}

const setNotificationTextColor = (level: NotificationLevel) => {
  switch (level) {
    case 'emergency':
    case 'alert':
    case 'critical':
    case 'error':
      return 'text-red-800'
    case 'warning':
    case 'notice':
      return 'text-yellow-800'
    case 'info':
    case 'debug':
      return 'text-sky-800'
    default:
      return 'text-gray-800'
  }
}

const setNotificationBackgroundColor = (level: NotificationLevel) => {
  switch (level) {
    case 'emergency':
    case 'alert':
    case 'critical':
    case 'error':
      return 'bg-red-600'
    case 'warning':
    case 'notice':
      return 'bg-yellow-600'
    case 'info':
    case 'debug':
      return 'bg-sky-600'
    default:
      return 'bg-gray-600'
  }
}

const getNotificationIcon = (level: NotificationLevel) => {
  switch (level) {
    case 'emergency':
    case 'alert':
    case 'critical':
    case 'error':
      return <ExclamationTriangleIcon className='h-5 w-5 text-red-600' aria-hidden='true' />
    case 'warning':
    case 'notice':
      return <ExclamationTriangleIcon className='h-5 w-5 text-yellow-600' aria-hidden='true' />
    case 'info':
    case 'debug':
      return <InfoCircledIcon className='h-5 w-5 text-sky-600' aria-hidden='true' />
    default:
      return <CheckCircleIcon className='h-5 w-5 text-gray-600' aria-hidden='true' />
  }
}

export const Toast: React.FC<ToastProps> = ({ notification, onMarkAsRead }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    onMarkAsRead(notification.id)
  }

  const notificationColor = getNotificationColor(notification.level)
  const notificationIcon = getNotificationIcon(notification.level)

  return (
    <>
      <div
        className={`relative flex max-h-[111px] min-h-[66px] w-full cursor-pointer rounded-md border border-gray-200 px-4 py-3 ${notificationColor}`}
        onClick={handleOpenModal}
      >
        <div className='flex flex-1 items-center justify-center gap-3'>
          <div className='shrink-0'>{notificationIcon}</div>
          <div className=''>
            <h3
              className={`max-w-64 text-balance text-sm font-medium ${setNotificationTextColor(
                notification?.level,
              )}`}
            >
              {notification?.header}
            </h3>
          </div>
          <div className='ml-auto'>
            <ChevronRightIcon
              className={`h-6 w-6 ${setNotificationTextColor(notification.level)}`}
              aria-hidden='true'
            />
          </div>
        </div>
      </div>
      <NotificationModal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        notification={notification}
        onMarkAsRead={onMarkAsRead}
      />
    </>
  )
}

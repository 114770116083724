import { useMutation, useQuery } from '@tanstack/react-query'
import { queryKeys } from 'constants/queryKeys'
import {
  addMemberToCensusBranch,
  addMemberToCensusSection,
  getAddedMembersCensusBranchList,
  getAddedMembersCensusSectionList,
  getCensusAdminDashboardData,
  getCensusBranchResult,
  getCensusDetails,
  getCensusMembersBranchList,
  getCensusMembersSectionList,
} from 'fetchers/censusFetchers'
import useAuth from 'hooks/useAuth'
import { SetStateAction, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { Button } from 'components/shadcn/ui/button'
import ErrorRoleModal from 'components/Atoms/ErrorRoleModal'
import { errorForbiddenHandler } from 'tools/queryHelpers'
import { getSection } from 'fetchers/sectionFetchers'
import { Card, CardContent, CardHeader, CardTitle } from 'components/shadcn/ui/card'
import { Label } from 'components/shadcn/ui/label'
import { errorToast, successToast } from 'tools/ToastHelpers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { notificationErrors } from 'error-data/notification'
import { PaginationExample } from '../../charts/PaginationComponent'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from 'components/shadcn/ui/breadcrumb'
import { getBranch } from 'fetchers/branchFetchers'
import { AxiosError } from 'axios'
import { Field } from '@headlessui/react'
import { Input } from 'components/shadcn/ui/input'
import {
  Select as ShadCnSelect,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/ui/select'
import { getInstitutionsList } from 'fetchers/institutionFetchers'
import { getPositionsList } from 'fetchers/positionFetchers'
import { MemberCard } from '../member-card/member-card'
import CensusBreadcrumbs from 'components/Atoms/Breadcrumbs/CensusBreadcrumbs'

const ITEMS_PER_PAGE = 10

const CensusDashboardSectionAsBranch = () => {
  const { userToken } = useAuth()
  const { censusId, sectionId } = useParams()

  const [currentPage, setCurrentPage] = useState(1)
  const [showUnconfirmedOnly, setShowUnconfirmedOnly] = useState(false)
  const [isAddingNewMember, setIsAddingNewMember] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [newMember, setNewMember] = useState({
    firstName: '',
    lastName: '',
    card: '',
    institution: '',
    position: '',
  })

  const {
    data: censusBranchAsSectionData,
    isLoading: isCensusBranchAsSectionLoading,
    error: censusBranchAsSectionError,
    refetch: refetchCensusBranchAsSection,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusMembersBranchList, censusId],
    queryFn: () => getCensusMembersBranchList(censusId ?? '', sectionId ?? ''),
    enabled: !!censusId && !!sectionId,
  })

  const { data: getCensusData, isLoading: isCensusDataLoading } = useQuery({
    queryKey: [userToken, queryKeys.censusDetails, censusId],
    queryFn: () => getCensusDetails(censusId ?? ''),
    enabled: !censusBranchAsSectionData,
  })

  const {
    data: censusDashboardData,
    isPending: isCensusDashboardDataPending,
    isLoading: isCensusDashboardDataLoading,
    error: censusDashboardDataError,
    refetch: refetchCensusDashboardData,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusDashboard, censusId],
    queryFn: () => getCensusAdminDashboardData(censusId ?? ''),
    enabled: !censusBranchAsSectionData,
  })

  const {
    data: censusSectionAsBranchResult,
    isLoading: isCensusSectionAsBranchResultLoading,
    error: censusSectionAsBranchResultError,
    refetch: refetchCensusSectionAsBranchResult,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusSectionAsBranchResult, censusId, sectionId],
    queryFn: () => getCensusBranchResult(censusId ?? '', sectionId ?? ''),
    enabled: !censusBranchAsSectionData,
  })

  const {
    data: addedBranchAsSectionMembersData,
    isLoading: isAddedBranchAsSectionMembersDataLoading,
    error: addedBranchAsSectionMembersDataError,
    refetch: refetchAddedBranchAsSectionMembersData,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusAddedMembersCensusBranchList, censusId],
    queryFn: () => getAddedMembersCensusBranchList(censusId ?? '', sectionId ?? ''),
    enabled: !!censusId && !!sectionId,
  })

  const { data: institution, isLoading: institutionLoading } = useQuery({
    queryKey: [userToken, queryKeys.institutionsList, 1, 1000],
    queryFn: () => getInstitutionsList(1, 1000),
    retry: false,
  })
  // POSITIONS
  const { data: position, isLoading: positionLoading } = useQuery({
    queryKey: [userToken, queryKeys.positionsList, 1, 1000],
    queryFn: () => getPositionsList(1, 1000),
    retry: false,
  })

  const institutionList = institution?.items
    ? institution?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const positionList = position?.items
    ? position?.items?.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const handleAddNewMember = () => {
    setIsAddingNewMember(true)
  }

  const handleCancelNewMember = () => {
    setIsAddingNewMember(false)
    setNewMember({
      firstName: '',
      lastName: '',
      card: '',
      institution: '',
      position: '',
    }) // Reset the form
  }

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target
    setNewMember((prevState) => ({ ...prevState, [name]: value }))
  }

  const addNewMemberMutation = useMutation({
    mutationFn: async ({
      censusID,
      sectionID,
      data,
    }: {
      censusID: string
      sectionID: string
      data: any
    }) => addMemberToCensusBranch(censusID, sectionID, data),
    onSuccess: () => {
      successToast('Dodano członka do sekcji.')
      refetchCensusBranchAsSection()
      refetchAddedBranchAsSectionMembersData()
      setIsAddingNewMember(false)
      setNewMember({
        firstName: '',
        lastName: '',
        card: '',
        institution: '',
        position: '',
      }) // Reset the form
      setIsLoading(false)
    },
    onError: (error) => {
      setIsLoading(false)
      mutationErrorHandler(
        error as AxiosError,
        notificationErrors.add,
        'Nie udało się dodać członka.',
      )
    },
  })

  const handleSubmitNewMember = () => {
    setIsLoading(true)
    if (!newMember.firstName) {
      errorToast('Podaj imię członka.')
      setIsLoading(false)
      return
    }

    if (!newMember.lastName) {
      errorToast('Podaj nazwisko członka.')
      setIsLoading(false)
      return
    }

    if (!newMember.institution) {
      errorToast('Podaj placówkę członka.')
      setIsLoading(false)
      return
    }

    if (!newMember.position) {
      errorToast('Podaj stanowisko członka.')
      setIsLoading(false)
      return
    }

    if (censusId && sectionId) {
      addNewMemberMutation.mutate({ censusID: censusId, sectionID: sectionId, data: newMember })
    } else {
      errorToast('Brakuje ID spisu lub sekcji.')
      setIsLoading(false)
    }
  }

  if (
    isCensusBranchAsSectionLoading ||
    isAddedBranchAsSectionMembersDataLoading ||
    institutionLoading ||
    positionLoading ||
    isCensusDataLoading ||
    isCensusDashboardDataLoading
  ) {
    return (
      <div>
        <div className='mb-4 h-4 w-full max-w-lg animate-pulse rounded-md bg-slate-300'></div>
        <div className='flex justify-between'>
          <div className='mb-4 h-4 w-full max-w-sm animate-pulse rounded-md bg-slate-300'></div>
          <div className='mb-4 h-4 w-full max-w-xs animate-pulse rounded-md bg-slate-300'></div>
        </div>
        <Card>
          <CardContent>
            <div className='my-4 h-4 w-full max-w-sm animate-pulse rounded-md bg-slate-300'></div>
            <div className='mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300'></div>
            <div className='mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300'></div>
            <div className='h-4 w-full max-w-xs animate-pulse rounded-md bg-slate-300'></div>
          </CardContent>
        </Card>
      </div>
    )
  }

  if (errorForbiddenHandler(censusBranchAsSectionError)) return <ErrorRoleModal />
  if (errorForbiddenHandler(addedBranchAsSectionMembersDataError)) return <ErrorRoleModal />

  // Filter members if "Pokaż niepotwierdzonych" is selected
  const filteredMembers = showUnconfirmedOnly
    ? censusBranchAsSectionData?.items?.filter((memberSection: any) => !memberSection.isConfirmed)
    : censusBranchAsSectionData?.items

  // Calculate pagination
  const totalPages = Math.ceil((filteredMembers?.length || 0) / ITEMS_PER_PAGE)
  const paginatedMembers = filteredMembers?.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE,
  )

  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage)
  }

  const handleFilterChange = () => {
    setShowUnconfirmedOnly(!showUnconfirmedOnly)
    setCurrentPage(1) // Reset to the first page when filter changes
  }

  return (
    <div>
      <div className='mb-6 flex items-center gap-4'> 
        <CensusBreadcrumbs
          censusId={censusId}
          censusName={getCensusData?.name}
          regionId={censusDashboardData?.subresults[0]?.unit?.id}
          regionName={censusDashboardData?.subresults[0]?.unit?.name}
          branchId={censusSectionAsBranchResult?.unit?.id}
          branchName={censusSectionAsBranchResult?.unit?.name}
          sectionId={sectionId}
          sectionName='Lista nieprzypisanych członków do sekcji'
        />
      </div>

      <div className='grid gap-6'>
        <Card>
          {/* <div className='text-3xl bg-red-500'>USERZY SEKCJI JAKO ODDZIAŁ</div> */}
          <CardHeader className='pb-0'>
            <CardTitle className='text-2xl'>Lista członków sekcji</CardTitle>
          </CardHeader>
          <CardContent>
            {censusBranchAsSectionData?.items?.length > 0 && (
              <div className='my-4 flex items-center justify-between'>
                <div className='flex flex-1 gap-4'>
                  <div className='flex items-center gap-2'>
                    <input
                      type='radio'
                      id='showAll'
                      name='show'
                      value='showAll'
                      checked={!showUnconfirmedOnly}
                      onChange={handleFilterChange}
                      className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                    />
                    <label
                      htmlFor='showAll'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Pokaż wszystkich
                    </label>
                  </div>
                  <div className='flex items-center gap-2'>
                    <input
                      type='radio'
                      id='showUnconfirmed'
                      name='show'
                      value='showUnconfirmed'
                      checked={showUnconfirmedOnly}
                      onChange={handleFilterChange}
                      className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                    />
                    <label
                      htmlFor='showUnconfirmed'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Pokaż niepotwierdzonych
                    </label>
                  </div>
                </div>
                <PaginationExample
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
            <div className='space-y-6'>
              {paginatedMembers?.length > 0 ? (
                paginatedMembers?.map((memberSection: any) => (
                  <MemberCard
                    censusMemberId={memberSection.id}
                    censusId={censusId}
                    key={memberSection.id}
                    memberSection={memberSection}
                    userToken={userToken}
                    refetchCensusMembersSection={refetchCensusBranchAsSection}
                    institutionList={institutionList}
                    positionList={positionList}
                  />
                ))
              ) : (
                <p className='pt-4 text-sm text-muted-foreground'>
                  {showUnconfirmedOnly
                    ? 'Brak niepotwierdzonych członków do wyświetlenia'
                    : 'Brak członków do wyświetlenia'}
                </p>
              )}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className='pb-4'>
            <CardTitle className='text-2xl'>Lista dodanych członków do sekcji</CardTitle>
          </CardHeader>
          <CardContent className='space-y-6'>
            {addedBranchAsSectionMembersData?.items?.length > 0 ? (
              addedBranchAsSectionMembersData?.items?.map((addedMember: any) => (
                <MemberCard
                  censusId={censusId}
                  key={addedMember.id}
                  memberSection={addedMember}
                  userToken={userToken}
                  refetchCensusMembersSection={refetchAddedBranchAsSectionMembersData}
                  isAddedMember={true}
                  institutionList={institutionList}
                  positionList={positionList}
                />
              ))
            ) : (
              <p className='pt-4 text-sm text-muted-foreground'>
                Brak dodanych członków do wyświetlenia
              </p>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader className={`${isAddingNewMember ? 'pb-4' : 'pb-0'}`}>
            <div className='item-center flex justify-between'>
              <CardTitle className='text-2xl'>Dodaj nowego członka do sekcji</CardTitle>
              <Button variant='outline' className='bg-white text-sm' onClick={handleAddNewMember}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='mr-2 h-5 w-5'
                  viewBox='0 0 16 16'
                >
                  <path d='M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4' />
                  <path d='M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z' />
                </svg>
                Dodaj członka
              </Button>
            </div>
          </CardHeader>
          <CardContent>
            {isAddingNewMember && (
              <Card>
                <CardHeader className='flex flex-row items-center justify-between space-y-0'>
                  <CardTitle className=''>Dodaj nowego członka</CardTitle>
                  <Button className='p-1' variant='ghost' onClick={handleCancelNewMember}>
                    <XMarkIcon className='h-5 w-5' />
                  </Button>
                </CardHeader>
                <CardContent>
                  <div className='mb-4 flex gap-4'>
                    <Field className='flex flex-1 flex-col gap-2'>
                      <Label htmlFor='firstName'>Imię</Label>
                      <Input
                        id='firstName'
                        name='firstName'
                        value={newMember.firstName}
                        onChange={handleInputChange}
                      />
                    </Field>
                    <Field className='flex flex-1 flex-col gap-2'>
                      <Label htmlFor='lastName'>Nazwisko</Label>
                      <Input
                        id='lastName'
                        name='lastName'
                        value={newMember.lastName}
                        onChange={handleInputChange}
                      />
                    </Field>
                    <Field className='flex flex-1 flex-col gap-2'>
                      <Label htmlFor='card'>Nr legitymacji</Label>
                      <Input
                        id='card'
                        name='card'
                        value={newMember.card}
                        onChange={handleInputChange}
                      />
                    </Field>
                  </div>
                  <div className='mb-4 flex gap-4'>
                    <Field className='flex flex-1 flex-col gap-2'>
                      <Label htmlFor='institution'>Placówka</Label>
                      <ShadCnSelect
                        onValueChange={(value) =>
                          setNewMember((prevState) => ({ ...prevState, institution: value }))
                        }
                      >
                        <SelectTrigger className='w-full max-w-[250px]'>
                          <SelectValue placeholder={newMember.institution} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup className='max-w-sm'>
                            {institutionList.map((option: any) => (
                              <SelectItem key={option.value} value={option.value}>
                                {option.label}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </ShadCnSelect>
                    </Field>
                    <Field className='flex flex-1 flex-col gap-2'>
                      <Label htmlFor='position'>Stanowisko</Label>
                      <ShadCnSelect
                        onValueChange={(value) =>
                          setNewMember((prevState) => ({ ...prevState, position: value }))
                        }
                      >
                        <SelectTrigger className='w-full max-w-[250px]'>
                          <SelectValue placeholder={newMember.position} />
                        </SelectTrigger>
                        <SelectContent id='position'>
                          <SelectGroup className='max-w-sm'>
                            {positionList.map((option: any) => (
                              <SelectItem key={option.value} value={option.value}>
                                {option.label}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </ShadCnSelect>
                    </Field>
                    <div className='flex-1'></div>
                  </div>

                  <Button onClick={handleSubmitNewMember} disabled={isLoading}>
                    {isLoading && <span className='loader mr-2'></span>}
                    {isLoading ? 'Dodawanie członka...' : 'Dodaj członka'}
                  </Button>
                </CardContent>
              </Card>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader className='pb-0'>
            <div className='item-center flex justify-between'>
              <CardTitle className='text-2xl'>Przejdź do podsumowania</CardTitle>
              <Link
                to={`/census/dashboard/${censusId}/section/${sectionId}/branch-as-section/summary`}
              >
                <Button>Podsumowanie sekcji</Button>
              </Link>
            </div>
          </CardHeader>
          <CardContent></CardContent>
        </Card>
      </div>
    </div>
  )
}

export default CensusDashboardSectionAsBranch

import { Typography } from 'components/Atoms/Typography'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { SectionFormClub } from 'components/Section/SectionFormClub'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { path } from 'constants/path'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'constants/queryKeys'
import { getInstitutionsList, getInstitutionsListByMe } from 'fetchers/institutionFetchers'
import { getBranchesList } from 'fetchers/branchFetchers'
import { useAppSelector } from 'app/hooks'
import { Card } from 'components/Atoms/Card'
import { useState } from 'react'
import { createSectionClub } from 'fetchers/sectionFetchers'
import { ICreateSectionClub } from 'types/section'
import useLegalFetcher from 'hooks/useLegalFetcher'
import useAuth from 'hooks/useAuth'
import { errorQuery, getLoadingHandler } from 'tools/queryHelpers'
import LoadingModal from 'components/Atoms/LoadingModal'
import { sectionErrors } from 'error-data/section'
import { mutationErrorHandler } from 'tools/errorHandler'
import { AxiosError } from 'axios'
import { successToast } from 'tools/ToastHelpers'

export const SectionCreateClub = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { userToken, isAllAdmin, isAllOD } = useAuth()
  const legalData = useLegalFetcher()
  const isAllow = isAllAdmin() || isAllOD()

  const { data: institutions, isLoading: institutionLoading } = useQuery({
    queryKey: [userToken, queryKeys.institutionsList],
    queryFn: () => getInstitutionsListByMe(1, 100),
  })

  const {
    data: branches,
    isLoading: branchesLoading,
    error,
  } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesList(1, 1000),
    retry: errorQuery,
  })

  const institutionsList = (institutions?.items || []).map((e: { name: string; id: string }) => {
    return { label: e.name, value: e.id }
  })
  const branchesList = (branches?.items || []).map((e: { name: string; id: string }) => {
    return { label: e?.name, value: e?.id }
  })

  const mutation = useMutation({
    mutationFn: (data: ICreateSectionClub) => createSectionClub(data),
    onSuccess: () => {
      successToast('Stworzono nowe ognisko.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.sectionsList] })
      navigate(path.section.list)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, sectionErrors.createClub, 'Nie udało się stworzyć ogniska.')
    },
  })

  const onSubmit = async (data: any) => {
    try {
      setIsSending(true)
      mutation.mutate(data)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się stworzyć ogniska.')
    }
  }

  const initData = {
    branch: '',
    name: '',
    email: '',
    phonePrimary: '',
    phoneSecondary: '',
    locationDescription: '',
    document: '',
    institutions: [],
  }

  const isLoading = institutionLoading || branchesLoading
  const loadingHandler = getLoadingHandler(error, isLoading, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />

  return (
    <>
      <Typography size='xl' weight='semibold' className='mb-5'>
        <Link to='/section'>Ognisko</Link> / Utwórz ognisko
      </Typography>
      <Card label='Nowe ognisko'>
        <SectionFormClub
          legalData={legalData}
          isSending={isSending}
          createRoot
          onSubmit={onSubmit}
          data={initData}
          navigate={navigate}
          branchesList={branchesList}
          institutionsList={institutionsList}
        />
      </Card>
    </>
  )
}

import { InstitutionForm } from 'components/Institution/InstitutionForm'
import { Link, useNavigate } from 'react-router-dom'
import { path } from 'constants/path'
import { LoadingSection } from 'components/Atoms/LoadingSection'
import { useAppSelector } from 'app/hooks'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'constants/queryKeys'
import {
  createInstitution,
  getInstitutionsGroupsList,
  getInstitutionsTypesList,
} from 'fetchers/institutionFetchers'
import { Card } from 'components/Atoms/Card'
import { Typography } from 'components/Atoms/Typography'
import { toast } from 'react-toastify'
import { IInstitutionCreate } from 'types/institution'
import { useState } from 'react'
import { getVoivodeship } from 'fetchers/voivodeshipFetchers'
import { getLoadingHandler } from 'tools/queryHelpers'
import LoadingModal from 'components/Atoms/LoadingModal'
import useAuth from 'hooks/useAuth'
import { AxiosError } from 'axios'
import { institutionErrors } from 'error-data/institution'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast } from 'tools/ToastHelpers'
import { getBranchesList } from 'fetchers/branchFetchers'

import {
  institutionCreateSchemaWithBranch,
  institutionCreateSchemaNoBranch,
} from 'schemas/institutionSchema'

interface IFormData {
  name: string
  branch?: string
  type: string
  headmasterPhone: string
  headmasterFirstName: string
  headmasterLastName: string
  headmasterEmail: string
  city: string
  postalCode: string
  streetName: string
  houseNr: string
  apartmentNr: string
  groups: (string | number)[]
}
export const InstitutionCreate = () => {
  const [isSending, setIsSending] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { userToken, isAllAdmin, isAllOD, isAllOK } = useAuth()
  const isAllow = isAllAdmin() || isAllOD() || isAllOK()
  const isRegion = isAllOK()
  const isAdmin = isAllAdmin()
  const isBranch = isAllOD()

  const showBranch = isRegion || isAdmin

  const validationSchema = isBranch
    ? institutionCreateSchemaNoBranch
    : institutionCreateSchemaWithBranch

  const {
    data: institutionTypes,
    isLoading: loadingInsitutions,
    error,
  } = useQuery({
    queryKey: [userToken, queryKeys.institutionTypesList],
    queryFn: () => getInstitutionsTypesList(1, 100),
  })

  const { data: institutionGroups, isLoading: loadingGroups } = useQuery({
    queryKey: [userToken, queryKeys.institutionGroupsList],
    queryFn: () => getInstitutionsGroupsList(),
  })

  const { data: branchData, isLoading: branchLoading } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesList(1, 100),
  })

  const mutation = useMutation({
    mutationFn: (data: IInstitutionCreate) => createInstitution(data),
    onSuccess: () => {
      successToast('Dodano placówkę.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.sectionsList] })
      navigate(path.institution.list)
    },
    onError: (error: AxiosError) => {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(error, institutionErrors.create, 'Nie udało się dodać placówki.')
    },
  })

  const onSubmit = async (data: IFormData) => {
    try {
      setIsSending(true)
      mutation.mutate(data)
    } catch (error) {
      setIsSending(false)
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się dodać placówki.')
    }
  }

  const data = {
    name: '',
    type: '',
    headmasterPhone: '',
    headmasterFirstName: '',
    headmasterLastName: '',
    headmasterEmail: '',
    city: '',
    postalCode: '',
    streetName: '',
    houseNr: '',
    apartmentNr: '',
    groups: [],
    branch: '',
  }

  const groupList = institutionGroups?.items
    ? institutionGroups?.items.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []
  const typeList = institutionTypes?.items
    ? institutionTypes?.items.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []
  const branchList = branchData?.items
    ? branchData?.items.map((item: any) => {
        return { label: item.name, value: item.id }
      })
    : []

  const isLoading = loadingInsitutions || loadingGroups
  const loadingHandler = getLoadingHandler(error, isLoading, !isAllow)
  if (loadingHandler.show) return <LoadingModal {...loadingHandler} />
  return (
    <>
      <Typography size='xl' weight='semibold'>
        <Link to='/institution'>Placówki</Link> / Dodaj placówkę
      </Typography>
      <Card label='Nowa placówka'>
        <InstitutionForm
          isSending={isSending}
          onSubmit={onSubmit}
          initData={data}
          branchList={branchList}
          groupList={groupList}
          typeList={typeList}
          showBranch={showBranch}
          // validationSchema
          validationSchema={validationSchema}
        />
      </Card>
    </>
  )
}

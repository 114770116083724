import { Checkbox } from '../Checkbox'

type Props = {
  keyName: string
  filteredData: any[]
  selectedData: string[]
  handleInputCheck: (value: string) => void
}

export const FilterSelectWindow = ({
  keyName,
  filteredData,
  selectedData,
  handleInputCheck,
}: Props) => {
  return (
    <ul
      className={`h-56 rounded-md border border-solid border-gray-300 ${
        filteredData?.length !== 0 ? 'overflow-y-scroll' : ''
      }`}
    >
      {filteredData.map((e: any, idx) => {
        const rKey = `r-${keyName}-${e.value}-${idx}`
        return (
          <li
            key={rKey}
            className='flex gap-2 whitespace-pre-line border-b border-solid border-gray-300 px-3 py-2 text-sm'
          >
            <Checkbox
              label={e.label}
              id={e.value}
              value={e.value}
              checked={selectedData.includes(e.value)}
              onChange={(e) => handleInputCheck(e.target.value)}
            />
          </li>
        )
      })}
      {filteredData?.length === 0 && (
        <li className='flex gap-2 border-b-2 border-solid border-gray-300 px-3 py-2 text-sm'>
          No data
        </li>
      )}
    </ul>
  )
}

export default FilterSelectWindow

import { Progress } from 'components/shadcn/ui/progress'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../../components/shadcn/ui/card'

type ProgressChartProps = {
  data: {
    done: number
    total: number
    value: number
  }
}

export function ProgressChart({ data }: ProgressChartProps) {
  return (
    <Card className='flex flex-col'>
      <CardHeader className='items-center pb-6'>
        <CardTitle>
          Progres {data.value.toFixed(2)}% ({data.done} / {data.total})
        </CardTitle>
      </CardHeader>
      <CardContent className='flex flex-1 items-center pb-0'>
        <Progress value={data.value} />
      </CardContent>
      <CardFooter className='flex-col gap-2 text-sm'></CardFooter>
    </Card>
  )
}

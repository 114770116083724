import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'
import { IInstitutionSearchQuery } from 'types/institution'

export const getInstitutionsList = async (
  page: number,
  count: number,
  query?: IInstitutionSearchQuery,
) => {
  const filters = []
  if (query?.name) {
    filters.push(`filter[name]=${query.name}`)
  }
  if (query?.city) {
    filters.push(`filter[city]=${query.city}`)
  }
  const filterData = filters.join('&')
  const res = await publicBaseApiURL.get(
    `${endpoint.structure.baseInstitutions}?page=${page}&perPage=${count}&${filterData}`,
    {},
  )
  return res?.data
}

export const getInstitutionsListByMe = async (
  page: number,
  count: number,
  query?: IInstitutionSearchQuery,
) => {
  const filters = []
  if (query?.name) {
    filters.push(`filter[name]=${query.name}`)
  }
  if (query?.city) {
    filters.push(`filter[city]=${query.city}`)
  }
  const filterData = filters.join('&')
  const res = await publicBaseApiURL.get(
    `${endpoint.structure.baseInstitutionsMe}?page=${page}&perPage=${count}&${filterData}`,
    {},
  )
  return res?.data
}

export const getInstitution = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.institution.byID(id), {})
  return res?.data
}

export const createInstitution = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.institution.singleInstitutionCreate, data)
  return res?.data
}

export const updateInstitution = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.institution.singleInstitutionUpdate(id), data)
  return res?.data
}

// TYPES

export const getInstitutionsTypesList = async (page: number, count: number) => {
  const res = await publicBaseApiURL.get(
    `${endpoint.institution.types.list}?page=${page}&perPage=${count}` + '&page=1&perPage=100',
    {},
  )
  return res?.data
}

export const getInstitutionType = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.institution.types.show(id), {})
  return res?.data
}

export const createInstitutionType = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.institution.types.create, data)
  return res?.data
}

export const updateInstitutionType = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.institution.types.update(id), data)
  return res?.data
}

// GROUPS
export const getInstitutionsGroupsList = async () => {
  const res = await publicBaseApiURL.get(endpoint.institution.groups.list, {})
  return res?.data
}
export const getInstitutionGroup = async (id: string) => {
  const res = await publicBaseApiURL.get(endpoint.institution.groups.show(id), {})
  return res?.data
}
export const createInstitutionGroup = async (data: any) => {
  const res = await publicBaseApiURL.post(endpoint.institution.groups.create, data)
  return res?.data
}
export const updateInstitutionGroup = async (id: string, data: any) => {
  const res = await publicBaseApiURL.put(endpoint.institution.groups.update(id), data)
  return res?.data
}

export const postArchiveInstitute = async (id?: string) => {
  const res = await publicBaseApiURL.post(endpoint.institution.archive(id), {})
  return res?.data
}

import React, { useState, useRef, useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { TextareaField } from 'components/Atoms/TextareaField'
import { TextField } from 'components/Atoms/TextField'
import { MultiSelect } from 'react-multi-select-component'
import { addOrderFile, createOrder } from 'fetchers/ordersFetchers'
import { queryKeys } from 'constants/queryKeys'
import { useAppSelector } from 'app/hooks'
import { useNavigate } from 'react-router-dom'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast, errorToast } from 'tools/ToastHelpers'
import { AxiosError } from 'axios'
import { IAxiosErrors } from 'types/axios-errors'
import { IOption } from 'types/form'
import { exportOrdersErorrs } from 'error-data/orders'
import { path } from 'constants/path'
import { getSectionsList } from 'fetchers/sectionFetchers'
import { getRegionBranches, getRegionsList } from 'fetchers/regionFetchers'
import { getBranchesList } from 'fetchers/branchFetchers'
import useAuth from 'hooks/useAuth'

const OrderCreate = () => {
  const [fileIsAdding, setFileIsAdding] = useState(false)
  const [orderName, setOrderName] = useState('')
  const [orderContent, setOrderContent] = useState('')
  const [sections, setSections] = useState<IOption[]>([])
  const [file, setFile] = useState<File>()
  const [fileIds, setFileIds] = useState<string[]>([])
  const [finalFiles, setFinalFiles] = useState<IOption[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { userToken } = useAppSelector((state: any) => state.authReducer)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { unitContext, userLoaded, isPOG, isAllOD, isAllOK, isAllAdmin } = useAuth()

  const isRegion = isAllOK()
  const isBranch = isAllOD()
  const isSection = isPOG()
  const isAdmin = isAllAdmin()

  const { data: regionData, isLoading: branchesIsLoading } = useQuery({
    queryKey: [userToken, queryKeys.regionList],
    queryFn: () => getRegionsList(0, 1000),
    enabled: isAdmin,
  })

  const { data: branchesData, isLoading: branchesLoading } = useQuery({
    queryKey: [userToken, queryKeys.branchesList],
    queryFn: () => getBranchesList(0, 1000),
    enabled: isRegion,
  })

  const { data: sectionData, isLoading: sectionLoading } = useQuery({
    queryKey: [userToken, queryKeys.sectionsList],
    queryFn: () => getSectionsList(0, 1000),
    enabled: isBranch,
  })

  const filteredItems = React.useMemo(() => {
    if (isAdmin && regionData) {
      return regionData.items.map((item: { id: string; name: string }) => ({
        value: item.id,
        label: item.name,
      }))
    } else if (isRegion && branchesData) {
      return branchesData.items.map((item: { id: string; name: string }) => ({
        value: item.id,
        label: item.name,
      }))
    } else if (isBranch && sectionData) {
      return sectionData.items.map((item: { id: string; name: string }) => ({
        value: item.id,
        label: item.name,
      }))
    } else {
      return []
    }
  }, [isAdmin, isRegion, isBranch, regionData, branchesData, sectionData])

  const mutation = useMutation({
    mutationFn: (data: any) => createOrder(data),
    onSuccess: (data) => {
      successToast('Dodano żądanie utworzenia polecenia.')
      queryClient.invalidateQueries({ queryKey: [queryKeys.trainingCreate] })
      navigate(path.orders.issued)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      console.log({ error })
      if (error.response?.status === 403) {
        errorToast(
          'Brak dostępu: Proszę upewnić się, że masz odpowiednie uprawnienia do utworzenia szkolenia.',
        )
        return
      }
      error.response?.data.errors.forEach((item: any) => {
        toast.warning(`${item.property}: ${item.error}`)
      })
      mutationErrorHandler(error, exportOrdersErorrs.create, 'Nie udało się utworzyć polecenia.')
    },
  })

  const fileMutation = useMutation({
    mutationFn: (data: File) => {
      return addOrderFile(data)
    },
    onSuccess: (data) => {
      const fileId = data.id
      const newFile = { value: fileId, label: `Plik ${fileIds.length + 1}` }
      setFileIds((prevFileIds) => [...prevFileIds, fileId])
      setFinalFiles((prevFinalFiles) => [...prevFinalFiles, newFile])
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
      successToast('Plik został dodany.')
      setFileIsAdding(false)
      setFile(undefined)
    },
    onError: (error: AxiosError<IAxiosErrors>) => {
      mutationErrorHandler(error, exportOrdersErorrs.create, 'Nie udało się dodać pliku.')
      setFileIsAdding(false)
    },
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : undefined
    setFile(selectedFile)
  }

  const handleAddFile = (e: React.FormEvent) => {
    e.preventDefault()
    if (!file) {
      errorToast('Plik jest wymagany.')
      return
    }
    setFileIsAdding(true)
    fileMutation.mutate(file)
  }

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault()
    if (!orderName.trim()) {
      errorToast('Nazwa pliku jest wymagana.')
      return
    }
    if (!orderContent.trim()) {
      errorToast('Treść polecenia jest wymagana.')
      return
    }
    if (sections.length === 0) {
      errorToast('Musisz wybrać co najmniej jedno pole do eksportu.')
      return
    }
    const exportData = {
      name: orderName,
      content: orderContent,
      units: sections.map((section) => section.value.toString()),
      files: fileIds,
    }
    mutation.mutate(exportData)
  }

  if (branchesLoading || sectionLoading || branchesIsLoading) {
    return (
      <Card label='Dodaj polecenie'>
        <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
          {[...Array(6)].map((_, index) => (
            <React.Fragment key={index}>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                <div className='h-4 w-full max-w-[112px] animate-pulse rounded-md bg-slate-200'></div>
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 dark:text-white dark:sm:border-white/5 sm:[&:nth-child(2)]:border-none'>
                <div className='h-4 w-full max-w-[287px] animate-pulse rounded-md bg-slate-200'></div>
              </dd>
            </React.Fragment>
          ))}
        </dl>
      </Card>
    )
  }

  const filteredFiles = fileIds.map((fileId, key) => ({
    value: fileId,
    label: `Plik ${key + 1}`,
  }))

  return (
    <>
      <Card label='Dodaj polecenie'>
        <form onSubmit={submitHandler}>
          <div className='grid max-w-4xl grid-cols-1 gap-4 pb-4 sm:grid-cols-2'>
            <TextField
              onChange={(e) => setOrderName(e.target.value)}
              label='Nazwa polecenia'
              placeholder='Wpisz nazwę polecenia'
              name='orderName'
              type='text'
              value={orderName}
            />
            <div>
              <p className='mb-1 block text-sm font-medium text-gray-700'>Sekcje</p>
              <MultiSelect
                options={filteredItems}
                value={sections}
                onChange={setSections}
                hasSelectAll={false}
                className='text-sm'
                overrideStrings={{
                  selectSomeItems: 'Zaznacz sekcje',
                  allItemsAreSelected: 'Wszystkie sekcje są zaznaczone',
                  selectAll: 'Zaznacz wszystkie',
                  search: 'Wyszukaj',
                }}
                labelledBy='Wyszukaj'
              />
            </div>
            <div className='md:col-span-2'>
              <TextareaField
                label='Treść polecenia'
                placeholder='Wpisz treść polecenia'
                name='orderContent'
                type='text'
                value={orderContent}
                onChange={(e) => setOrderContent(e.target.value)}
              />
            </div>
            <div className='md:col-span-2'>
              <p className='mb-1 block text-sm font-medium text-gray-700'>Pliki</p>
              <MultiSelect
                options={filteredFiles}
                value={finalFiles}
                onChange={setFinalFiles}
                hasSelectAll={false}
                className='text-sm'
                overrideStrings={{
                  selectSomeItems: 'Zaznacz pliki',
                  allItemsAreSelected: 'Wszystkie pliki są zaznaczone',
                  selectAll: 'Zaznacz wszystkie',
                  search: 'Wyszukaj',
                  noOptions: 'Brak plików',
                }}
                labelledBy='Wyszukaj'
              />
            </div>
            <div className='md:col-span-2'>
              <label className='mb-1 block text-sm font-medium text-gray-700'>Dodaj plik</label>
              <div className='flex max-w-fit items-center gap-2'>
                <input
                  type='file'
                  name='file'
                  id='file'
                  ref={fileInputRef}
                  className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  onChange={handleFileChange}
                />
                <Button
                  className='mt-1 flex min-w-[130px] justify-center text-center disabled:cursor-not-allowed'
                  disabled={!file || fileIsAdding}
                  label={
                    fileIsAdding ? (
                      <>
                        Dodaję plik
                        <span className='loader ml-1'></span>
                      </>
                    ) : (
                      'Dodaj plik'
                    )
                  }
                  onClick={handleAddFile}
                />
              </div>
            </div>
          </div>
          <hr className='my-4' />
          <div className='mt-4 flex justify-center'>
            <Button
              label={mutation.isPending ? 'Tworzenie...' : 'Utwórz polecenie'}
              disabled={mutation.isPending}
            />
          </div>
        </form>
      </Card>
      <div className='my-4 flex justify-center'>
        <Button label='Powrót' variant='secondary' onClick={() => navigate(path.orders.issued)} />
      </div>
    </>
  )
}

export default OrderCreate

import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'components/shadcn/ui/card'
import { ChartContainer, ChartTooltip, ChartTooltipContent } from 'components/shadcn/ui/chart'
import { useMemo } from 'react'

const chartConfig = {
  completed: {
    label: 'Ukończone',
    color: 'hsl(var(--chart-1))',
  },
} as const

const formatDate = (date: string) =>
  new Date(date).toLocaleDateString('pl-PL', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

const getDaysBetween = (start: string, end: string) => {
  const startDate = new Date(start)
  const endDate = new Date(end)
  return Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
}

const getDateRangeType = (days: number) => {
  if (days <= 31) return 'days'
  if (days <= 90) return 'weeks'
  return 'months'
}

const generateChartData = (
  subresults: any[],
  dateFrom: string,
  dateTo: string,
  rangeType: 'days' | 'weeks' | 'months',
) => {
  const data: { date: string; completed: number }[] = []
  const startDate = new Date(dateFrom)
  const endDate = new Date(dateTo)

  const currentDate = new Date(startDate)
  while (currentDate <= endDate) {
    const formattedDate = currentDate.toISOString().split('T')[0]
    const completedCount = subresults.filter(
      (result) => result.completedAt && result.completedAt.startsWith(formattedDate),
    ).length

    data.push({
      date: formattedDate,
      completed: completedCount,
    })

    switch (rangeType) {
      case 'days':
        currentDate.setDate(currentDate.getDate() + 1)
        break
      case 'weeks':
        currentDate.setDate(currentDate.getDate() + 7)
        break
      case 'months':
        currentDate.setMonth(currentDate.getMonth() + 1)
        break
    }
  }

  return data
}

interface CensusBranchData {
  dateFrom: string
  dateTo: string
  subresults: any[]
}

interface BarChartComponentProps {
  censusBranchData: CensusBranchData
  dashbordType: string
}

export function BarChartComponent({ censusBranchData, dashbordType }: BarChartComponentProps) {
  // Check if the necessary data is available before proceeding
  if (
    !censusBranchData ||
    !censusBranchData.dateFrom ||
    !censusBranchData.dateTo ||
    !censusBranchData.subresults
  ) {
    return (
      <div>
        <p>Brak danych do wygenerowania wykresu</p>
      </div>
    )
  }

  const { dateFrom, dateTo, subresults } = censusBranchData
  const daysBetween = getDaysBetween(dateFrom, dateTo)
  const rangeType = getDateRangeType(daysBetween)

  const chartData = useMemo(
    () => generateChartData(subresults, dateFrom, dateTo, rangeType),
    [subresults, dateFrom, dateTo, rangeType],
  )

  const totalCompleted = useMemo(
    () => chartData.reduce((acc, curr) => acc + curr.completed, 0),
    [chartData],
  )

  return (
    <Card className='h-full'>
      <CardHeader className='flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row'>
        <div className='flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6'>
          <CardTitle>Wykres ukończonych {dashbordType}</CardTitle>
          <CardDescription>
            Pokazuje liczbę ukończonych {dashbordType} w czasie trwania spisu
          </CardDescription>
        </div>
        <div className='flex'>
          <div className='z-1 relative flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left sm:border-l sm:border-t-0 sm:px-8 sm:py-6'>
            <span className='text-xs text-muted-foreground'>{chartConfig.completed.label}</span>
            <span className='text-lg font-bold leading-none sm:text-3xl'>{totalCompleted}</span>
          </div>
        </div>
      </CardHeader>
      <CardContent className='px-2 sm:p-6'>
        <ChartContainer config={chartConfig} className='aspect-auto h-[290px] w-full'>
          <BarChart
            data={chartData}
            margin={{
              left: 0,
              right: 12,
              top: 12,
              bottom: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey='date'
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              tickFormatter={(value) => formatDate(value)}
            />
            <YAxis axisLine={false} tickLine={false} tickFormatter={(value) => value.toString()} />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  className='w-[150px]'
                  nameKey='completed'
                  labelFormatter={(value) => formatDate(value)}
                />
              }
            />
            <Bar dataKey='completed' fill={'var(--color-completed)'} />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}

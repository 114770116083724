import { BellAlertIcon } from '@heroicons/react/20/solid'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { queryKeys } from 'constants/queryKeys'
import { Button } from 'components/shadcn/ui/button'
import { Alert, AlertTitle, AlertDescription } from 'components/shadcn/ui/alert'
import {
  DialogHeader,
  DialogFooter,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from 'components/shadcn/ui/dialog'
import { notificationErrors } from 'error-data/notification'
import { acknowledgeCensusSectionResultsRejection } from 'fetchers/censusFetchers'
import { useState, useEffect } from 'react'
import { mutationErrorHandler } from 'tools/errorHandler'
import { successToast } from 'tools/ToastHelpers'

export interface RejectedItem {
  id: string
  description: string
  createdBy: {
    email: string
  }
  censusId: string
  sectionId: string
  ackedAt: string
  ackedBy: {
    email: string
  }
}

interface RejectedMessagesAlertProps {
  rejectedItems: RejectedItem[]
  censusId: string
  sectionId: string
  sectionName: string
  rejectedId: string
  onRejectionConfirmed: () => void
}

const RejectedMessagesAlert: React.FC<RejectedMessagesAlertProps> = ({
  rejectedItems,
  censusId,
  sectionId,
  rejectedId,
  sectionName,
  onRejectionConfirmed,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [visibleItems, setVisibleItems] = useState<RejectedItem[]>([])
  const [openDialogId, setOpenDialogId] = useState<string | null>(null)
  const queryClient = useQueryClient()

  useEffect(() => {
    setVisibleItems(rejectedItems)
  }, [rejectedItems])

  const acknowledgeRejectSectionMutation = useMutation({
    mutationFn: (data: { censusId: string; sectionId: string; rejectedId: string }) =>
      acknowledgeCensusSectionResultsRejection(data.censusId, data.sectionId, data.rejectedId),
    onSuccess: (_, variables) => {
      successToast('Odrzucomy wynik został zaakceptowany.')
      setVisibleItems((prev) => prev.filter((item) => item.id !== variables.rejectedId))
      queryClient.invalidateQueries({ queryKey: [queryKeys.censusDashboard, censusId] })
      queryClient.invalidateQueries({ queryKey: [queryKeys.censusRejectedMembersList, censusId] })
      setIsLoading(false)
      onRejectionConfirmed()
    },
    onError: (error: any) => {
      mutationErrorHandler(
        error,
        notificationErrors.update,
        'Odrzucony wynik nie został zaakceptowany.',
      )
      setIsLoading(false)
    },
  })

  const handleAccept = (item: RejectedItem) => {
    setIsLoading(true)

    const confirmed = window.confirm('Czy jesteś pewien, że chcesz zaakceptować odrzucenie spisu?')
    if (!confirmed) {
      setIsLoading(false)
      return // Exit if the user clicks "Cancel"
    }
    acknowledgeRejectSectionMutation.mutate({
      censusId: censusId,
      sectionId: sectionId,
      rejectedId: rejectedId,
    })
    setOpenDialogId(null)
  }

  const handleDismiss = (itemId: string) => {
    setVisibleItems((prev) => prev.filter((item) => item.id !== itemId))
  }

  if (visibleItems.length === 0) return null

  return (
    <>
      <Alert variant='destructive' className='mb-4'>
        <BellAlertIcon className='h-4 w-4' />
        <AlertTitle className='pt-1'>Ważny komunikat dla {sectionName}</AlertTitle>
        <AlertDescription>
          <ul>
            {visibleItems.map((item) => (
              <li key={item.id} className='flex items-center justify-between'>
                <span>
                  {item.description} (Odrzucone przez: {item.createdBy.email})
                </span>
                <div>
                  <Button
                    variant='outline'
                    size='sm'
                    onClick={() => setOpenDialogId(item.id)}
                    className='mr-2'
                  >
                    Potwierdź odrzucenie
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        </AlertDescription>
      </Alert>

      {visibleItems.map((item) => (
        <Dialog
          key={item.id}
          open={openDialogId === item.id}
          onOpenChange={() => setOpenDialogId(null)}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Szczegóły dot. odrzuconego wyniku</DialogTitle>
              <DialogDescription></DialogDescription>
            </DialogHeader>
            <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(30%,theme(spacing.80))_auto] sm:text-sm/6'>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                Nazwa sekcji
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:[&:nth-child(2)]:border-none'>
                {sectionName}
              </dd>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                Odrzucone przez
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:[&:nth-child(2)]:border-none'>
                {item.createdBy.email}
              </dd>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                Powód odrzucenia
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:[&:nth-child(2)]:border-none'>
                {item.description}
              </dd>
            </dl>
            <DialogFooter>
              <Button variant='outline' onClick={() => setOpenDialogId(null)}>
                Anuluj
              </Button>
              <Button disabled={isLoading} onClick={() => handleAccept(item)}>
                {isLoading ? 'Akceptowanie...' : 'Zaakceptuj'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      ))}
    </>
  )
}

export default RejectedMessagesAlert

import { HoverIcon } from './HoverIcon'
import { useNavigate } from 'react-router-dom'
import { Tag } from 'react-tag-autocomplete'
import clsx from 'clsx'

type Props = {
  tag: Tag
  editToken?: boolean
  removeToken?: () => void
}

export const TagFrame = ({ tag, removeToken, editToken, ...tagProps }: Props) => {
  const navigate = useNavigate()
  const updateHoover = () => {
    navigate(`/settings/tags/${String(tag.value)}/update`)
  }
  const cursor = removeToken || editToken ? 'cursor-pointer' : 'cursor-default'
  const buttonHandler = () => removeToken && removeToken()

  return (
    <button
      className={clsx(
        'inline-flex items-center rounded-full border border-transparent bg-slate-200 px-2.5 py-0.5 text-xs font-semibold text-gray-900 transition-colors hover:bg-slate-200/80 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
        cursor,
      )}
      {...tagProps}
      onClick={buttonHandler}
    >
      <p>{tag.label}</p>
      {removeToken && (
        <HoverIcon
          noMargin
          noPointer={!removeToken && !editToken}
          iconName='TrashIcon'
          title='Usuń'
        />
      )}{' '}
      {editToken && (
        <HoverIcon
          noMargin
          noPointer={!removeToken && !editToken}
          iconName='PencilIcon'
          title='Edytuj'
          onClick={updateHoover}
        />
      )}
    </button>
  )
}

export default TagFrame

import { PencilIcon, EyeIcon } from '@heroicons/react/24/outline'
import { HoverIcon } from 'components/Atoms/HoverIcon'
import { useNavigate } from 'react-router-dom'
import { wipMessage } from 'tools/ToastHelpers'

type Props = {
  route?: string
  id?: string
}

export const MembersSectionActionCell = ({ route, id }: Props) => {
  const navigate = useNavigate()
  const showHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    navigate(`/${route}/${id}`)
  }
  // const editHandler = () => navigate(`/${route}/${id}/update`)
  return (
    <div className='flex'>
      <HoverIcon iconName='EyeIcon' title='Zobacz' onClick={showHandler} />
    </div>
  )
}

export default MembersSectionActionCell

import * as React from 'react'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import { cn } from 'components/lib/utils'

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn('relative h-2 w-full overflow-hidden rounded-full bg-primary/20', className)}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className={`h-full w-full flex-1 transition-all ${
        // based on value we can change the color of the progress bar from red, to yelow to green
        value === 0
          ? 'bg-primary/20'
          : (value ?? 0) < 50
            ? 'bg-red-500'
            : (value ?? 0) < 80
              ? 'bg-yellow-500'
              : 'bg-green-500'
      }`}
      style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
    />
  </ProgressPrimitive.Root>
))
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }

export const queryKeys = {
  branch: 'branch',
  branchesList: 'branchesList',
  branchMigrations: 'branchMigrations',
  branchMigrationDetail: 'branchMigrationDetail',
  cardReqTypeDict: 'cardReqTypeDict',
  cardReqEventDict: 'cardReqEventUser',
  cardReqStatusDict: 'cardReqStatusDict',
  cartReq: 'cartReq',
  censusList: 'censusList',
  censusListMe: 'censusListMe',
  censusDetails: 'censusDetails',
  censusDashboard: 'censusDashboard',
  censusDashboardRegion: 'censusDashboardRegion',
  censusAddedMemberSectionList: 'censusAddedMemberSectionList',
  censusAddedMembersCensusBranchList: 'censusAddedMembersCensusBranchList',
  censusMembersBranchList: 'censusMembersBranchList',
  CensusDashboardBranch: 'CensusDashboardBranch',
  censusMembersList: 'censusMembersList',
  censusBranchResult: 'censusBranchResult',
  censusSectionResult: 'censusSectionResult',
  censusSectionAsBranchResult: 'censusSectionAsBranchResult',
  censusSectionSummary: 'censusSectionSummary',
  censusRejectedMembersList: 'censusRejectedMembersList',
  censusCreate: 'censusCreate',
  censusListBranches: 'censusListBranches',
  censusSurveyData: 'censusSurveyData',
  unitCensusList: 'unitCensusList',
  getCard: 'getCard',
  cardReqCheck: 'cardReqCheck',
  cardsList: 'cardsList',
  cardStatus: 'cardStatus',
  cardsListRequest: 'cardsListRequest',
  declaration: 'declaration',
  declarationList: 'declarationList',
  documentList: 'documentList',
  documentDetails: 'documentDetails',
  documentCreate: 'documentCreate',
  documentArchive: 'documentArchive',
  education: 'education',
  educationList: 'educationList',
  institution: 'institution',
  institutionGroups: 'institutionGroups',
  institutionListByMe: 'institutionListByMe',
  institutionGroupsList: 'institutionGroupsList',
  institutionsList: 'institutionsList',
  institutionType: 'institutionType',
  institutionTypesList: 'institutionTypesList',
  legal: 'legal',
  legalList: 'legalList',
  member: 'member',
  memberArchive: 'memberArchive',
  memberChangelog: 'memberChangelog',
  memberChangeLogDetails: 'memberChangeLogDetails',
  memberContact: 'memberContact',
  memberEmployment: 'memberEmployment',
  memberFunctions: 'memberFunctions',
  memberFunctionById: 'memberFunctionById',
  memberSensitive: 'memberSensitive',
  membersList: 'membersList',
  memberDecorations: 'memberDecorations',
  memberDecorationsList: 'memberDecorationsList',
  addMemberDecoration: 'addMemberDecoration',
  getDecorationsList: 'getDecorationsList',
  getUserAvialableDecorations: 'getUserAvialableDecorations',
  memberSectionList: 'memberSectionList',
  memberInstitutionList: 'memberInstitutionList',
  memberTags: 'memberTags',
  memberExportDictionary: 'memberExportDictionary',
  memberExportList: 'memberExportList',
  memberExportSingle: 'memberExportSingle',
  notifications: 'notifications',
  notificationsList: 'notificationsList',
  notificationsListAlerts: 'notificationsListAlerts',
  notificationsListAlertsMarkAsRead: 'notificationsListAlertsMarkAsRead',
  ordersIssuedList: 'ordersIssuedList',
  ordersReceivedList: 'ordersReceivedList',
  ordersReceiedDetails: 'ordersReceiedDetails',
  ordersIssuedDetails: 'ordersIssuedDetails',
  orderFileByID: 'orderFileByID',
  position: 'position',
  positionsList: 'positionsList',
  reasonsList: 'reasonsList',
  region: 'region',
  regionBranchesList: 'regionBranchesList',
  regionList: 'regionList',
  regionUsersList: 'regionUsersList',
  remoteSearch: 'remoteSearch',
  rodoAccessDeclarations: 'rodoAccessDeclarations',
  rodoAccessDeclarationsCriteria: 'rodoAccessDeclarationsCriteria',
  rodoAccessMembers: 'rodoAccessMembers',
  rodoAccessMembersCriteria: 'rodoAccessMembersCriteria',
  rodoResourceDeclarations: 'rodoResourceDeclarations',
  rodoResourceDeclarationsCriteria: 'rodoResourceDeclarationsCriteria',
  rodoResourceMembers: 'rodoResourceMembers',
  rodoResourceMembersCriteria: 'rodoResourceMembersCriteria',
  roleList: 'roleList',
  section: 'section',
  sectionsList: 'sectionsList',
  sectionArchive: 'sectionArchive',
  sectionsTypesList: 'sectionsTypesList',
  structureFunctions: 'structureFunctions',
  structureFunctionsStatus: 'structureFunctionsStatus',
  subject: 'subject',
  subjectsList: 'subjectsList',
  baseStructures: 'baseStructures',
  termOfOffice: 'termOfOffice',
  tag: 'tag',
  tagsList: 'tagsList',
  tagsListAvailable: 'tagsListAvailable',
  trainingList: 'trainingList',
  trainingDetails: 'trainingDetails',
  trainingMembersList: 'trainingMembersList',
  trainingAddMemberToList: 'trainingAddMemberToList',
  trainingRemoveMember: 'trainingRemoveMember',
  trainingArchive: 'trainingArchive',
  trainingCreate: 'trainingCreate',
  trainingDetailsFile: 'trainingDetailsFile',
  unitPosition: 'unitPosition',
  unitPositionsList: 'unitPositionsList',
  unitDecorationList: 'unitDecorationList',
  user: 'user',
  usersList: 'usersList',
  voivodeships: 'voivodeships',
}

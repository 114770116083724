import React from 'react'
import { Button } from 'components/Atoms/Button'
import { Card } from 'components/Atoms/Card'
import { ProgressBar } from 'components/Atoms/ProgressBar'
import { Table } from 'components/Atoms/Table'
import { Typography } from 'components/Atoms/Typography'
import { CharArea } from 'components/Atoms/ChartArea'
import { path } from 'constants/path'
import { useNavigate } from 'react-router-dom'

export const MainManagment = () => {
  const navigate = useNavigate()

  const data = React.useMemo(
    () => [
      {
        section: 'Okręg 1',
        confirm: 'Tak',
        left: 3,
        join: 0,
        progress: '26',
      },
      {
        section: 'Okręg 2',
        confirm: 'Tak',
        left: 3,
        join: 6,
        progress: '50',
      },
      {
        section: 'Okręg 3',
        confirm: 'nie',
        left: 49,
        join: 32,
        progress: '80',
      },
      {
        section: 'Okręg 4',
        confirm: 'Tak',
        left: 123,
        join: 991,
        progress: '13',
      },
      {
        section: 'Okręg 5',
        confirm: 'Tak',
        left: 15,
        join: 43,
        progress: '85',
      },
    ],
    [],
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Okręg',
        accessor: 'section',
      },
      {
        Header: 'Czy potwierdzone?',
        accessor: 'confirm',
      },
      {
        Header: '+0 (0/0)',
        accessor: 'left',
        Cell: (props: any) => {
          const original = props.row.original
          const summary = original.join - original.left
          return (
            <div className={`${summary > 0 ? 'bg-green-300' : 'bg-red-300'} absolute inset-0 p-4`}>
              {summary > 0 ? `+ ${summary}` : summary} ({original.join} / {original.left})
            </div>
          )
        },
      },
      {
        Header: 'Progress',
        accessor: 'progress',
        Cell: (row: any) => {
          return <ProgressBar completed={row.cell.value} />
        },
      },
      {
        Header: 'Szczegóły',
        accessor: 'details',
        Cell: (row: any) => (
          <p className='cursor-pointer' onClick={() => navigate(path.section.details)}>
            Zobacz
          </p>
        ),
      },
    ],
    [],
  )

  return (
    <>
      <Card label='Wypełnianie statystyk'>
        <Typography className='text-center'>
          Obowiązek statystyczny musi zostać zrealizowany do <strong>31.08.2022</strong>
        </Typography>
        <Typography weight='semibold' className='text-center'>
          (zostało 2 dni)
        </Typography>

        <div className='mt-5 flex justify-center'>
          <Button label='ZAMKNIJ RAPORT' />
        </div>
      </Card>

      <div className='flex flex-col items-center justify-center p-5'>
        <Typography weight='semibold'>PROGRESS 26% (21/80)</Typography>
        <div className='my-5 flex w-1/2 justify-center'>
          <ProgressBar completed={26} />
        </div>
        <Typography weight='semibold'>Podsumowanie zmian +501 / -217</Typography>
      </div>

      <div className='my-5'>
        <CharArea />
      </div>

      <Table columns={columns} data={data} />
    </>
  )
}

import { publicBaseApiURL } from 'api'
import { endpoint } from 'constants/endpoints'

export const getNotificationList = async (page: number, perPage: number, filter?: any) => {
  const res = await publicBaseApiURL.get(endpoint.notifications.list(page, perPage, filter))
  return res?.data
}

export const markAllNotificationsAsRead = async (notificationIds: string[]) => {
  const res = await publicBaseApiURL.post(endpoint.notifications.readAll, {
    items: notificationIds.map((id) => ({ id })),
  })
  return res?.data
}

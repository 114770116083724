import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { queryKeys } from 'constants/queryKeys'
import {
  closeCensusBranch,
  closeCensusSection,
  getCensusAdminDashboardData,
  getCensusBranches,
  getCensusBranchResult,
  getCensusDetails,
  getCensusListBranches,
  getCensusSectionResult,
  getCensusSectionResultsRejections,
  getCensusSectionSummary,
  rejectCensusSectionResults,
  reopenCensusBranch,
  reopenCensusSection,
} from 'fetchers/censusFetchers'
import { getSection } from 'fetchers/sectionFetchers'
import { getBranch } from 'fetchers/branchFetchers'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from 'components/shadcn/ui/breadcrumb'
import { Card, CardContent, CardHeader, CardTitle } from 'components/shadcn/ui/card'
import useAuth from 'hooks/useAuth'
import { useMemo, useState } from 'react'
import { Table } from 'components/Atoms/Table'
import { Field } from '@headlessui/react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/ui/select'
import { Button } from 'components/shadcn/ui/button'
import { successToast } from 'tools/ToastHelpers'
import { mutationErrorHandler } from 'tools/errorHandler'
import { notificationErrors } from 'error-data/notification'
import { LockClosedIcon, LockOpen1Icon } from '@radix-ui/react-icons'
import ChartHorizontal from '../../charts/ChartHorizontal'
import CensusBreadcrumbs from 'components/Atoms/Breadcrumbs/CensusBreadcrumbs'

const CensusDashboardSectionSummaryAsBranch = () => {
  const [isHovered, setIsHovered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { userToken } = useAuth()
  const { censusId, sectionId } = useParams()
  const navigate = useNavigate()

  const {
    data: censusSectionAsBranchResult,
    isLoading: isCensusSectionAsBranchResultLoading,
    error: censusSectionAsBranchResultError,
    refetch: refetchCensusSectionAsBranchResult,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusSectionAsBranchResult, censusId, sectionId],
    queryFn: () => getCensusBranchResult(censusId ?? '', sectionId ?? ''),
    enabled: !!censusId && !!sectionId,
  })

  const { data: getCensusData, isLoading: isCensusDataLoading } = useQuery({
    queryKey: [userToken, queryKeys.censusDetails, censusId],
    queryFn: () => getCensusDetails(censusId ?? ''),
    enabled: !isCensusSectionAsBranchResultLoading,
  })

  const {
    data: censusDashboardData,
    isPending: isCensusDashboardDataPending,
    isLoading: isCensusDashboardDataLoading,
    error: censusDashboardDataError,
    refetch: refetchCensusDashboardData,
  } = useQuery({
    queryKey: [userToken, queryKeys.censusDashboard, getCensusData?.id],
    queryFn: () => getCensusAdminDashboardData(getCensusData?.id),
  })

  const {
    data: censusBranchData,
    isPending: isCensusBranchDataPending,
    isLoading: isCensusBranchDataLoading,
    error: censusBranchDataError,
    refetch: refetchCensusBranchData,
  } = useQuery({
    queryKey: [userToken, queryKeys.CensusDashboardBranch, censusId],
    queryFn: () => getCensusBranches(censusId ?? '', sectionId ?? ''),
    enabled: !isCensusSectionAsBranchResultLoading,
  })

  const { data: censusListBranches, isLoading: isCensusListBranchesLoading } = useQuery({
    queryKey: [userToken, queryKeys.censusListBranches, sectionId],
    queryFn: () => getCensusListBranches(sectionId ?? ''),
    enabled: !isCensusSectionAsBranchResultLoading,
  })

  const handleCensusSelect = (selectedSectionId: string) => {
    navigate(`/census/dashboard/${censusId}/section/${selectedSectionId}/summary`)
  }

  const translateCensusStatus = (status: string) => {
    switch (status) {
      case 'awaiting':
        return 'Oczekiwanie'
      case 'in_progress':
        return 'W trakcie'
      case 'completed':
        return 'Zakończony'
      case 'canceled':
        return 'Anulowany'
      default:
        return 'Brak statusu'
    }
  }

  const closeCensusSectionMutation = useMutation({
    mutationFn: async ({ censusID, sectionId }: { censusID: string; sectionId: string }) =>
      closeCensusBranch(censusID, sectionId),
    onSuccess: () => {
      successToast('Spis sekcji został zamknięty.')
      setIsLoading(false)
      refetchCensusSectionAsBranchResult()
    },
    onError: (error: any) => {
      mutationErrorHandler(error, notificationErrors.update, 'Nie udało się zamknąć spisu sekcji.')
      setIsLoading(false)
    },
  })

  const reopenCensusSectionMutation = useMutation({
    mutationFn: async ({ censusID, sectionId }: { censusID: string; sectionId: string }) =>
      reopenCensusBranch(censusID, sectionId),
    onSuccess: () => {
      successToast('Spis sekcji został otwarty.')
      setIsLoading(false)
      refetchCensusSectionAsBranchResult()
    },
    onError: (error: any) => {
      mutationErrorHandler(error, notificationErrors.update, 'Nie udało się otworzyć spisu sekcji.')
      setIsLoading(false)
    },
  })

  const handleSectionMutation = async () => {
    setIsLoading(true)
    try {
      // Determine the action: open or close
      const action = censusSectionAsBranchResult?.status === 'completed' ? 'otworzyć' : 'zamknąć'

      // Ask for confirmation before proceeding
      const confirmed = window.confirm(`Czy jesteś pewien, że chcesz ${action} spis sekcji?`)
      if (!confirmed) {
        setIsLoading(false)
        return // Exit if the user clicks "Cancel"
      }

      if (censusSectionAsBranchResult?.status === 'in_progress') {
        closeCensusSectionMutation.mutate({ censusID: censusId ?? '', sectionId: sectionId ?? '' })
      } else if (censusSectionAsBranchResult?.status === 'completed') {
        reopenCensusSectionMutation.mutate({ censusID: censusId ?? '', sectionId: sectionId ?? '' })
      }
    } catch (error) {
      console.error(error)
      mutationErrorHandler(null, null, 'Nie udało się zmienić statusu spisu sekcji.')
      setIsLoading(false)
    }
  }

  const censusMembersSectionColumns = useMemo(() => {
    return [
      {
        Header: 'Nr',
        accessor: 'number',
        Cell: ({ row }: any) => {
          return row.index + 1
        },
      },
      {
        Header: 'Imię i nazwisko',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return row.original?.firstName + ' ' + row.original?.lastName
        },
      },
      {
        Header: 'Placówka',
        accessor: 'employment',
        Cell: ({ row }: any) => {
          // return translateInstitutionNameByID(row.original?.id)
        },
      },
      {
        Header: 'Stanowisko',
        accessor: 'position',
        Cell: ({ row }: any) => {
          // return translatePositionNameByID(row.original?.id)
        },
      },
    ]
  }, [])

  if (
    isCensusSectionAsBranchResultLoading ||
    isCensusDataLoading ||
    isCensusDashboardDataLoading ||
    isCensusBranchDataLoading
  ) {
    return (
      <div>
        <div className='mb-4 h-4 w-full max-w-lg animate-pulse rounded-md bg-slate-300'></div>
        <div className='flex justify-between'>
          <div className='mb-4 h-4 w-full max-w-sm animate-pulse rounded-md bg-slate-300'></div>
          <div className='mb-4 h-4 w-full max-w-xs animate-pulse rounded-md bg-slate-300'></div>
        </div>
        <Card>
          <CardContent>
            <div className='my-4 h-4 w-full max-w-sm animate-pulse rounded-md bg-slate-300'></div>
            <div className='mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300'></div>
            <div className='mb-4 h-4 w-full max-w-[80%] animate-pulse rounded-md bg-slate-300'></div>
            <div className='h-4 w-full max-w-xs animate-pulse rounded-md bg-slate-300'></div>
          </CardContent>
        </Card>
      </div>
    )
  }
  return (
    <div className='space-y-6'>
      <CensusBreadcrumbs
        censusId={censusId}
        regionId={censusDashboardData?.subresults[0]?.unit?.id}
        branchId={censusSectionAsBranchResult?.unit?.id}
        sectionId={sectionId}
        censusName={
          censusDashboardData?.subresults.find((census: any) => census.unit.id === censusId)?.unit
            .name
        }
        regionName={censusDashboardData?.subresults[0]?.unit?.name}
        branchName={censusSectionAsBranchResult?.unit?.name}
        sectionName='Podsumowanie dla nieprzypisanych do ogniska'
      />

      <div className='grid grid-cols-1 gap-6 lg:grid-cols-3'>
        <Card>
          <CardHeader>
            <CardTitle className='text-2xl'>Członkowie nieprzypisani do ogniska</CardTitle>
          </CardHeader>
          <CardContent className='space-y-4'>
            <dl className='grid grid-cols-1 text-base/6 sm:grid-cols-[min(50%,theme(spacing.80))_auto] sm:text-sm/6'>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                Status spisu
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:[&:nth-child(2)]:border-none'>
                {translateCensusStatus(censusSectionAsBranchResult?.status)}
              </dd>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                Data rozpoczęcia
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:[&:nth-child(2)]:border-none'>
                {new Date(getCensusData?.dateFrom).toLocaleDateString()}
              </dd>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                Data zakończenia
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:[&:nth-child(2)]:border-none'>
                {new Date(getCensusData?.dateTo).toLocaleDateString()}
              </dd>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                Data aktualizacji
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:[&:nth-child(2)]:border-none'>
                {new Date(censusSectionAsBranchResult?.updatedAt.slice(0, 10)).toLocaleDateString()}
              </dd>
              <dt className='col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3'>
                Autor aktualizacji
              </dt>
              <dd className='pb-3 pt-1 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:[&:nth-child(2)]:border-none'>
                {censusSectionAsBranchResult?.updatedBy}
              </dd>
            </dl>
            {/* <Button
              disabled={isLoading || isCensusSectionAsBranchResultLoading}
              variant={
                censusSectionAsBranchResult?.status === 'in_progress' ? 'destructive' : 'default'
              }
              onClick={handleSectionMutation}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {isLoading ? <span className='loader'></span> : ''}
              {censusSectionAsBranchResult?.status === 'in_progress'
                ? 'Zamknij spis Sekcji'
                : 'Otwórz spis Sekcji'}
              {censusSectionAsBranchResult?.status === 'in_progress' ? (
                <>
                  {isHovered ? (
                    <LockClosedIcon className='w-4 h-4 ml-1' />
                  ) : (
                    <LockOpen1Icon className='w-4 h-4 ml-1' />
                  )}
                </>
              ) : (
                <>
                  {isHovered ? (
                    <LockOpen1Icon className='w-4 h-4 ml-1' />
                  ) : (
                    <LockClosedIcon className='w-4 h-4 ml-1' />
                  )}
                </>
              )}
            </Button> */}
          </CardContent>
        </Card>

        <Card className='flex flex-col'>
          <ChartHorizontal
            isSectionSummary={true}
            data={{
              balanceChangeIn: censusSectionAsBranchResult?.balanceChangeIn,
              balanceChangeOut: censusSectionAsBranchResult?.balanceChangeOut,
            }}
          />
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Przełącz widok podsumowania na inną sekcję</CardTitle>
          </CardHeader>
          <CardContent className='space-y-4'>
            <Field className='flex flex-1 flex-col gap-2'>
              <Select onValueChange={handleCensusSelect}>
                <SelectTrigger className='w-full max-w-[300px]' id='censusSelect'>
                  <SelectValue placeholder='Wybierz sekcję' />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className='max-w-sm'>
                    {censusBranchData?.subresults.map((census: any) =>
                      census.unit.id === sectionId ? (
                        <SelectItem
                          key={census.unit.id}
                          value={`${census.unit.id}/branch-as-section`}
                        >
                          Członkowie nieprzypisani do ogniska
                        </SelectItem>
                      ) : (
                        <SelectItem key={census.unit.id} value={census.unit.id}>
                          {census.unit.name}
                        </SelectItem>
                      ),
                    )}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </Field>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader className='pb-0'>
          <CardTitle className='text-2xl'>Lista osób</CardTitle>
        </CardHeader>
        <CardContent>
          {censusSectionAsBranchResult?.membersList.length === 0 ? (
            <p className='pt-4 text-sm text-muted-foreground'>Brak osób w sekcji</p>
          ) : (
            <Table
              data={censusSectionAsBranchResult?.membersList || []}
              columns={censusMembersSectionColumns}
            />
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader className='pb-0'>
          <CardTitle className='text-2xl'>Lista dodanych osób do sekcji</CardTitle>
        </CardHeader>
        <CardContent>
          {censusSectionAsBranchResult?.membersInList.length === 0 ? (
            <p className='pt-4 text-sm text-muted-foreground'>Brak dodanych osób do sekcji</p>
          ) : (
            <Table
              data={censusSectionAsBranchResult?.membersInList || []}
              columns={censusMembersSectionColumns}
            />
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader className='pb-0'>
          <CardTitle className='text-2xl'>Lista usuniętych osób z sekcji</CardTitle>
        </CardHeader>
        <CardContent>
          {censusSectionAsBranchResult?.membersOutList.length === 0 ? (
            <p className='pt-4 text-sm text-muted-foreground'>Brak usuniętych osób w sekcji</p>
          ) : (
            <Table
              data={censusSectionAsBranchResult?.membersOutList || []}
              columns={censusMembersSectionColumns}
            />
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default CensusDashboardSectionSummaryAsBranch
